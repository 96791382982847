/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSMSSend = /* GraphQL */ `query GetSMSSend($id: ID!) {
  getSMSSend(id: $id) {
    id
    sitesID
    sendNo
    schdAt
    purpose
    cntSMS
    inputType
    sendType
    sendStatus
    smsSubject
    smsContent
    costCenter
    isDemo
    isAd
    receiveNumbers
    cdrId
    reqId
    confirmMemberIdx
    confirmMemberId
    confirmMemberName
    confirmAt
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSMSSendQueryVariables,
  APITypes.GetSMSSendQuery
>;
export const listSMSSends = /* GraphQL */ `query ListSMSSends(
  $filter: ModelSMSSendFilterInput
  $limit: Int
  $nextToken: String
) {
  listSMSSends(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      sendNo
      schdAt
      purpose
      cntSMS
      inputType
      sendType
      sendStatus
      smsSubject
      smsContent
      costCenter
      isDemo
      isAd
      receiveNumbers
      cdrId
      reqId
      confirmMemberIdx
      confirmMemberId
      confirmMemberName
      confirmAt
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSMSSendsQueryVariables,
  APITypes.ListSMSSendsQuery
>;
export const syncSMSSends = /* GraphQL */ `query SyncSMSSends(
  $filter: ModelSMSSendFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSMSSends(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      sendNo
      schdAt
      purpose
      cntSMS
      inputType
      sendType
      sendStatus
      smsSubject
      smsContent
      costCenter
      isDemo
      isAd
      receiveNumbers
      cdrId
      reqId
      confirmMemberIdx
      confirmMemberId
      confirmMemberName
      confirmAt
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSMSSendsQueryVariables,
  APITypes.SyncSMSSendsQuery
>;
export const sMSSendsBySitesID = /* GraphQL */ `query SMSSendsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSMSSendFilterInput
  $limit: Int
  $nextToken: String
) {
  sMSSendsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      sendNo
      schdAt
      purpose
      cntSMS
      inputType
      sendType
      sendStatus
      smsSubject
      smsContent
      costCenter
      isDemo
      isAd
      receiveNumbers
      cdrId
      reqId
      confirmMemberIdx
      confirmMemberId
      confirmMemberName
      confirmAt
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SMSSendsBySitesIDQueryVariables,
  APITypes.SMSSendsBySitesIDQuery
>;
export const searchSMSSends = /* GraphQL */ `query SearchSMSSends(
  $filter: SearchableSMSSendFilterInput
  $sort: [SearchableSMSSendSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableSMSSendAggregationInput]
) {
  searchSMSSends(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      sendNo
      schdAt
      purpose
      cntSMS
      inputType
      sendType
      sendStatus
      smsSubject
      smsContent
      costCenter
      isDemo
      isAd
      receiveNumbers
      cdrId
      reqId
      confirmMemberIdx
      confirmMemberId
      confirmMemberName
      confirmAt
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchSMSSendsQueryVariables,
  APITypes.SearchSMSSendsQuery
>;
export const getSMSTemplateMsg = /* GraphQL */ `query GetSMSTemplateMsg($id: ID!) {
  getSMSTemplateMsg(id: $id) {
    id
    sitesID
    isEvent
    sendCategory
    sendCategoryDetail
    isBizTalk
    bizTalkCode
    description
    smsSubject
    smsType
    smsContent
    extraInfo
    adMessage
    highlightType
    highLightTitle
    highLightComment
    buttons {
      type
      name
      url_mobile
      url_pc
      scheme_android
      scheme_ios
      __typename
    }
    sendCondition
    sendTime
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSMSTemplateMsgQueryVariables,
  APITypes.GetSMSTemplateMsgQuery
>;
export const listSMSTemplateMsgs = /* GraphQL */ `query ListSMSTemplateMsgs(
  $filter: ModelSMSTemplateMsgFilterInput
  $limit: Int
  $nextToken: String
) {
  listSMSTemplateMsgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      isEvent
      sendCategory
      sendCategoryDetail
      isBizTalk
      bizTalkCode
      description
      smsSubject
      smsType
      smsContent
      extraInfo
      adMessage
      highlightType
      highLightTitle
      highLightComment
      sendCondition
      sendTime
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSMSTemplateMsgsQueryVariables,
  APITypes.ListSMSTemplateMsgsQuery
>;
export const syncSMSTemplateMsgs = /* GraphQL */ `query SyncSMSTemplateMsgs(
  $filter: ModelSMSTemplateMsgFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSMSTemplateMsgs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      isEvent
      sendCategory
      sendCategoryDetail
      isBizTalk
      bizTalkCode
      description
      smsSubject
      smsType
      smsContent
      extraInfo
      adMessage
      highlightType
      highLightTitle
      highLightComment
      sendCondition
      sendTime
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSMSTemplateMsgsQueryVariables,
  APITypes.SyncSMSTemplateMsgsQuery
>;
export const sMSTemplateMsgsBySitesID = /* GraphQL */ `query SMSTemplateMsgsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSMSTemplateMsgFilterInput
  $limit: Int
  $nextToken: String
) {
  sMSTemplateMsgsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      isEvent
      sendCategory
      sendCategoryDetail
      isBizTalk
      bizTalkCode
      description
      smsSubject
      smsType
      smsContent
      extraInfo
      adMessage
      highlightType
      highLightTitle
      highLightComment
      sendCondition
      sendTime
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SMSTemplateMsgsBySitesIDQueryVariables,
  APITypes.SMSTemplateMsgsBySitesIDQuery
>;
export const searchSMSTemplateMsgs = /* GraphQL */ `query SearchSMSTemplateMsgs(
  $filter: SearchableSMSTemplateMsgFilterInput
  $sort: [SearchableSMSTemplateMsgSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableSMSTemplateMsgAggregationInput]
) {
  searchSMSTemplateMsgs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      isEvent
      sendCategory
      sendCategoryDetail
      isBizTalk
      bizTalkCode
      description
      smsSubject
      smsType
      smsContent
      extraInfo
      adMessage
      highlightType
      highLightTitle
      highLightComment
      sendCondition
      sendTime
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchSMSTemplateMsgsQueryVariables,
  APITypes.SearchSMSTemplateMsgsQuery
>;
export const getDailyQuizGroup = /* GraphQL */ `query GetDailyQuizGroup($id: ID!) {
  getDailyQuizGroup(id: $id) {
    id
    title
    DailyQuizBases {
      nextToken
      startedAt
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyQuizGroupQueryVariables,
  APITypes.GetDailyQuizGroupQuery
>;
export const listDailyQuizGroups = /* GraphQL */ `query ListDailyQuizGroups(
  $filter: ModelDailyQuizGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyQuizGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyQuizGroupsQueryVariables,
  APITypes.ListDailyQuizGroupsQuery
>;
export const syncDailyQuizGroups = /* GraphQL */ `query SyncDailyQuizGroups(
  $filter: ModelDailyQuizGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDailyQuizGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDailyQuizGroupsQueryVariables,
  APITypes.SyncDailyQuizGroupsQuery
>;
export const getDailyQuizBase = /* GraphQL */ `query GetDailyQuizBase($id: ID!) {
  getDailyQuizBase(id: $id) {
    id
    title
    quizDate
    quizBeginDate
    quizEndDate
    dailyquizgroupID
    correctAnswerRate
    participants
    DailyQuizQuestions {
      nextToken
      startedAt
      __typename
    }
    DailyQuizSentences {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyQuizBaseQueryVariables,
  APITypes.GetDailyQuizBaseQuery
>;
export const listDailyQuizBases = /* GraphQL */ `query ListDailyQuizBases(
  $filter: ModelDailyQuizBaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyQuizBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      quizDate
      quizBeginDate
      quizEndDate
      dailyquizgroupID
      correctAnswerRate
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyQuizBasesQueryVariables,
  APITypes.ListDailyQuizBasesQuery
>;
export const syncDailyQuizBases = /* GraphQL */ `query SyncDailyQuizBases(
  $filter: ModelDailyQuizBaseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDailyQuizBases(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      quizDate
      quizBeginDate
      quizEndDate
      dailyquizgroupID
      correctAnswerRate
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDailyQuizBasesQueryVariables,
  APITypes.SyncDailyQuizBasesQuery
>;
export const dailyQuizBasesByDailyquizgroupID = /* GraphQL */ `query DailyQuizBasesByDailyquizgroupID(
  $dailyquizgroupID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDailyQuizBaseFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyQuizBasesByDailyquizgroupID(
    dailyquizgroupID: $dailyquizgroupID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      quizDate
      quizBeginDate
      quizEndDate
      dailyquizgroupID
      correctAnswerRate
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyQuizBasesByDailyquizgroupIDQueryVariables,
  APITypes.DailyQuizBasesByDailyquizgroupIDQuery
>;
export const searchDailyQuizBases = /* GraphQL */ `query SearchDailyQuizBases(
  $filter: SearchableDailyQuizBaseFilterInput
  $sort: [SearchableDailyQuizBaseSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDailyQuizBaseAggregationInput]
) {
  searchDailyQuizBases(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      quizDate
      quizBeginDate
      quizEndDate
      dailyquizgroupID
      correctAnswerRate
      participants
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDailyQuizBasesQueryVariables,
  APITypes.SearchDailyQuizBasesQuery
>;
export const getDailyQuizSentence = /* GraphQL */ `query GetDailyQuizSentence($id: ID!) {
  getDailyQuizSentence(id: $id) {
    id
    sentenceImage
    sentenceText
    dailyquizbaseID
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyQuizSentenceQueryVariables,
  APITypes.GetDailyQuizSentenceQuery
>;
export const listDailyQuizSentences = /* GraphQL */ `query ListDailyQuizSentences(
  $filter: ModelDailyQuizSentenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyQuizSentences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sentenceImage
      sentenceText
      dailyquizbaseID
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyQuizSentencesQueryVariables,
  APITypes.ListDailyQuizSentencesQuery
>;
export const syncDailyQuizSentences = /* GraphQL */ `query SyncDailyQuizSentences(
  $filter: ModelDailyQuizSentenceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDailyQuizSentences(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sentenceImage
      sentenceText
      dailyquizbaseID
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDailyQuizSentencesQueryVariables,
  APITypes.SyncDailyQuizSentencesQuery
>;
export const dailyQuizSentencesByDailyquizbaseID = /* GraphQL */ `query DailyQuizSentencesByDailyquizbaseID(
  $dailyquizbaseID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDailyQuizSentenceFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyQuizSentencesByDailyquizbaseID(
    dailyquizbaseID: $dailyquizbaseID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sentenceImage
      sentenceText
      dailyquizbaseID
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyQuizSentencesByDailyquizbaseIDQueryVariables,
  APITypes.DailyQuizSentencesByDailyquizbaseIDQuery
>;
export const getDailyQuizQuestion = /* GraphQL */ `query GetDailyQuizQuestion($id: ID!) {
  getDailyQuizQuestion(id: $id) {
    id
    questionNumber
    questionContent
    questions
    answer
    commentary
    relatedTerms
    correctAnswerRate
    DailyQuizQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    dailyquizbaseID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyQuizQuestionQueryVariables,
  APITypes.GetDailyQuizQuestionQuery
>;
export const listDailyQuizQuestions = /* GraphQL */ `query ListDailyQuizQuestions(
  $filter: ModelDailyQuizQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyQuizQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionNumber
      questionContent
      questions
      answer
      commentary
      relatedTerms
      correctAnswerRate
      dailyquizbaseID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyQuizQuestionsQueryVariables,
  APITypes.ListDailyQuizQuestionsQuery
>;
export const syncDailyQuizQuestions = /* GraphQL */ `query SyncDailyQuizQuestions(
  $filter: ModelDailyQuizQuestionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDailyQuizQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      questionNumber
      questionContent
      questions
      answer
      commentary
      relatedTerms
      correctAnswerRate
      dailyquizbaseID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDailyQuizQuestionsQueryVariables,
  APITypes.SyncDailyQuizQuestionsQuery
>;
export const dailyQuizQuestionsByDailyquizbaseID = /* GraphQL */ `query DailyQuizQuestionsByDailyquizbaseID(
  $dailyquizbaseID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDailyQuizQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyQuizQuestionsByDailyquizbaseID(
    dailyquizbaseID: $dailyquizbaseID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionNumber
      questionContent
      questions
      answer
      commentary
      relatedTerms
      correctAnswerRate
      dailyquizbaseID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyQuizQuestionsByDailyquizbaseIDQueryVariables,
  APITypes.DailyQuizQuestionsByDailyquizbaseIDQuery
>;
export const getDailyQuizQuestionAnswer = /* GraphQL */ `query GetDailyQuizQuestionAnswer($id: ID!) {
  getDailyQuizQuestionAnswer(id: $id) {
    id
    questionNumber
    userAnswer
    isCorrect
    dailyquizquestionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDailyQuizQuestionAnswerQueryVariables,
  APITypes.GetDailyQuizQuestionAnswerQuery
>;
export const listDailyQuizQuestionAnswers = /* GraphQL */ `query ListDailyQuizQuestionAnswers(
  $filter: ModelDailyQuizQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  listDailyQuizQuestionAnswers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionNumber
      userAnswer
      isCorrect
      dailyquizquestionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDailyQuizQuestionAnswersQueryVariables,
  APITypes.ListDailyQuizQuestionAnswersQuery
>;
export const syncDailyQuizQuestionAnswers = /* GraphQL */ `query SyncDailyQuizQuestionAnswers(
  $filter: ModelDailyQuizQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDailyQuizQuestionAnswers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      questionNumber
      userAnswer
      isCorrect
      dailyquizquestionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDailyQuizQuestionAnswersQueryVariables,
  APITypes.SyncDailyQuizQuestionAnswersQuery
>;
export const dailyQuizQuestionAnswersByDailyquizquestionID = /* GraphQL */ `query DailyQuizQuestionAnswersByDailyquizquestionID(
  $dailyquizquestionID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDailyQuizQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  dailyQuizQuestionAnswersByDailyquizquestionID(
    dailyquizquestionID: $dailyquizquestionID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      questionNumber
      userAnswer
      isCorrect
      dailyquizquestionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DailyQuizQuestionAnswersByDailyquizquestionIDQueryVariables,
  APITypes.DailyQuizQuestionAnswersByDailyquizquestionIDQuery
>;
export const getMockExamSubject = /* GraphQL */ `query GetMockExamSubject($id: ID!) {
  getMockExamSubject(id: $id) {
    id
    subjectName
    memo
    typeQuestionName
    scoreComments {
      startScore
      endScore
      typeQuestion
      evaluationStandard
      comment
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMockExamSubjectQueryVariables,
  APITypes.GetMockExamSubjectQuery
>;
export const listMockExamSubjects = /* GraphQL */ `query ListMockExamSubjects(
  $filter: ModelMockExamSubjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listMockExamSubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      subjectName
      memo
      typeQuestionName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMockExamSubjectsQueryVariables,
  APITypes.ListMockExamSubjectsQuery
>;
export const syncMockExamSubjects = /* GraphQL */ `query SyncMockExamSubjects(
  $filter: ModelMockExamSubjectFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMockExamSubjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      subjectName
      memo
      typeQuestionName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMockExamSubjectsQueryVariables,
  APITypes.SyncMockExamSubjectsQuery
>;
export const searchMockExamSubjects = /* GraphQL */ `query SearchMockExamSubjects(
  $filter: SearchableMockExamSubjectFilterInput
  $sort: [SearchableMockExamSubjectSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableMockExamSubjectAggregationInput]
) {
  searchMockExamSubjects(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      subjectName
      memo
      typeQuestionName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMockExamSubjectsQueryVariables,
  APITypes.SearchMockExamSubjectsQuery
>;
export const getMockExamBase = /* GraphQL */ `query GetMockExamBase($id: ID!) {
  getMockExamBase(id: $id) {
    id
    title
    productIdx
    mockExamBaseIdx
    MockExamQuestions {
      nextToken
      startedAt
      __typename
    }
    mockexamcompanyID
    MockExamUsers {
      nextToken
      startedAt
      __typename
    }
    MockExamCompany {
      id
      companyName
      affiliatesType
      affiliatesName
      companyLogo
      examSubjects
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMockExamBaseQueryVariables,
  APITypes.GetMockExamBaseQuery
>;
export const listMockExamBases = /* GraphQL */ `query ListMockExamBases(
  $filter: ModelMockExamBaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listMockExamBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMockExamBasesQueryVariables,
  APITypes.ListMockExamBasesQuery
>;
export const syncMockExamBases = /* GraphQL */ `query SyncMockExamBases(
  $filter: ModelMockExamBaseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMockExamBases(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMockExamBasesQueryVariables,
  APITypes.SyncMockExamBasesQuery
>;
export const mockExamBasesByMockexamcompanyID = /* GraphQL */ `query MockExamBasesByMockexamcompanyID(
  $mockexamcompanyID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMockExamBaseFilterInput
  $limit: Int
  $nextToken: String
) {
  mockExamBasesByMockexamcompanyID(
    mockexamcompanyID: $mockexamcompanyID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MockExamBasesByMockexamcompanyIDQueryVariables,
  APITypes.MockExamBasesByMockexamcompanyIDQuery
>;
export const searchMockExamBases = /* GraphQL */ `query SearchMockExamBases(
  $filter: SearchableMockExamBaseFilterInput
  $sort: [SearchableMockExamBaseSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableMockExamBaseAggregationInput]
) {
  searchMockExamBases(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMockExamBasesQueryVariables,
  APITypes.SearchMockExamBasesQuery
>;
export const getMockExamQuestion = /* GraphQL */ `query GetMockExamQuestion($id: ID!) {
  getMockExamQuestion(id: $id) {
    questionCount
    optionCount
    solveLimitMinute
    pauseLimitMinute
    questionPaper
    commentaryPaper
    sortValue
    mockexambaseID
    MockExamSubject {
      id
      subjectName
      memo
      typeQuestionName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamQuestionMockExamSubjectId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMockExamQuestionQueryVariables,
  APITypes.GetMockExamQuestionQuery
>;
export const listMockExamQuestions = /* GraphQL */ `query ListMockExamQuestions(
  $filter: ModelMockExamQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listMockExamQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMockExamQuestionsQueryVariables,
  APITypes.ListMockExamQuestionsQuery
>;
export const syncMockExamQuestions = /* GraphQL */ `query SyncMockExamQuestions(
  $filter: ModelMockExamQuestionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMockExamQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMockExamQuestionsQueryVariables,
  APITypes.SyncMockExamQuestionsQuery
>;
export const mockExamQuestionsByMockexambaseID = /* GraphQL */ `query MockExamQuestionsByMockexambaseID(
  $mockexambaseID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMockExamQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  mockExamQuestionsByMockexambaseID(
    mockexambaseID: $mockexambaseID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MockExamQuestionsByMockexambaseIDQueryVariables,
  APITypes.MockExamQuestionsByMockexambaseIDQuery
>;
export const getMockExamCompany = /* GraphQL */ `query GetMockExamCompany($id: ID!) {
  getMockExamCompany(id: $id) {
    id
    companyName
    affiliatesType
    affiliatesName
    companyLogo
    examSubjects
    isUse
    MockExamBases {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMockExamCompanyQueryVariables,
  APITypes.GetMockExamCompanyQuery
>;
export const listMockExamCompanies = /* GraphQL */ `query ListMockExamCompanies(
  $filter: ModelMockExamCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listMockExamCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      companyName
      affiliatesType
      affiliatesName
      companyLogo
      examSubjects
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMockExamCompaniesQueryVariables,
  APITypes.ListMockExamCompaniesQuery
>;
export const syncMockExamCompanies = /* GraphQL */ `query SyncMockExamCompanies(
  $filter: ModelMockExamCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMockExamCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      companyName
      affiliatesType
      affiliatesName
      companyLogo
      examSubjects
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMockExamCompaniesQueryVariables,
  APITypes.SyncMockExamCompaniesQuery
>;
export const getMockExamUser = /* GraphQL */ `query GetMockExamUser($id: ID!) {
  getMockExamUser(id: $id) {
    id
    memberIdx
    memberId
    memberName
    affiliatesName
    memberProductIdx
    memberMocktestIdx
    MockExamUserQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    MockExamUserQuestionAnswerTemps {
      nextToken
      startedAt
      __typename
    }
    mockexambaseID
    MockExamBase {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMockExamUserQueryVariables,
  APITypes.GetMockExamUserQuery
>;
export const listMockExamUsers = /* GraphQL */ `query ListMockExamUsers(
  $filter: ModelMockExamUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listMockExamUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      memberIdx
      memberId
      memberName
      affiliatesName
      memberProductIdx
      memberMocktestIdx
      mockexambaseID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMockExamUsersQueryVariables,
  APITypes.ListMockExamUsersQuery
>;
export const syncMockExamUsers = /* GraphQL */ `query SyncMockExamUsers(
  $filter: ModelMockExamUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMockExamUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      memberIdx
      memberId
      memberName
      affiliatesName
      memberProductIdx
      memberMocktestIdx
      mockexambaseID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMockExamUsersQueryVariables,
  APITypes.SyncMockExamUsersQuery
>;
export const mockExamUsersByMockexambaseID = /* GraphQL */ `query MockExamUsersByMockexambaseID(
  $mockexambaseID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMockExamUserFilterInput
  $limit: Int
  $nextToken: String
) {
  mockExamUsersByMockexambaseID(
    mockexambaseID: $mockexambaseID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      memberIdx
      memberId
      memberName
      affiliatesName
      memberProductIdx
      memberMocktestIdx
      mockexambaseID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MockExamUsersByMockexambaseIDQueryVariables,
  APITypes.MockExamUsersByMockexambaseIDQuery
>;
export const searchMockExamUsers = /* GraphQL */ `query SearchMockExamUsers(
  $filter: SearchableMockExamUserFilterInput
  $sort: [SearchableMockExamUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableMockExamUserAggregationInput]
) {
  searchMockExamUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      memberIdx
      memberId
      memberName
      affiliatesName
      memberProductIdx
      memberMocktestIdx
      mockexambaseID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchMockExamUsersQueryVariables,
  APITypes.SearchMockExamUsersQuery
>;
export const getMockExamUserQuestionAnswer = /* GraphQL */ `query GetMockExamUserQuestionAnswer($id: ID!) {
  getMockExamUserQuestionAnswer(id: $id) {
    id
    answers {
      questionNumber
      answer
      isCorrect
      __typename
    }
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    score
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerMockExamQuestionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMockExamUserQuestionAnswerQueryVariables,
  APITypes.GetMockExamUserQuestionAnswerQuery
>;
export const listMockExamUserQuestionAnswers = /* GraphQL */ `query ListMockExamUserQuestionAnswers(
  $filter: ModelMockExamUserQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  listMockExamUserQuestionAnswers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      mockexamansweruserID
      score
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamUserQuestionAnswerMockExamQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMockExamUserQuestionAnswersQueryVariables,
  APITypes.ListMockExamUserQuestionAnswersQuery
>;
export const syncMockExamUserQuestionAnswers = /* GraphQL */ `query SyncMockExamUserQuestionAnswers(
  $filter: ModelMockExamUserQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMockExamUserQuestionAnswers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      mockexamansweruserID
      score
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamUserQuestionAnswerMockExamQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMockExamUserQuestionAnswersQueryVariables,
  APITypes.SyncMockExamUserQuestionAnswersQuery
>;
export const mockExamUserQuestionAnswersByMockexamansweruserID = /* GraphQL */ `query MockExamUserQuestionAnswersByMockexamansweruserID(
  $mockexamansweruserID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMockExamUserQuestionAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  mockExamUserQuestionAnswersByMockexamansweruserID(
    mockexamansweruserID: $mockexamansweruserID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      mockexamansweruserID
      score
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamUserQuestionAnswerMockExamQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MockExamUserQuestionAnswersByMockexamansweruserIDQueryVariables,
  APITypes.MockExamUserQuestionAnswersByMockexamansweruserIDQuery
>;
export const getMockExamUserQuestionAnswerTemp = /* GraphQL */ `query GetMockExamUserQuestionAnswerTemp($id: ID!) {
  getMockExamUserQuestionAnswerTemp(id: $id) {
    id
    answers {
      questionNumber
      answer
      __typename
    }
    remainQuestionTime
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerTempMockExamQuestionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMockExamUserQuestionAnswerTempQueryVariables,
  APITypes.GetMockExamUserQuestionAnswerTempQuery
>;
export const listMockExamUserQuestionAnswerTemps = /* GraphQL */ `query ListMockExamUserQuestionAnswerTemps(
  $filter: ModelMockExamUserQuestionAnswerTempFilterInput
  $limit: Int
  $nextToken: String
) {
  listMockExamUserQuestionAnswerTemps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      remainQuestionTime
      mockexamansweruserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamUserQuestionAnswerTempMockExamQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMockExamUserQuestionAnswerTempsQueryVariables,
  APITypes.ListMockExamUserQuestionAnswerTempsQuery
>;
export const syncMockExamUserQuestionAnswerTemps = /* GraphQL */ `query SyncMockExamUserQuestionAnswerTemps(
  $filter: ModelMockExamUserQuestionAnswerTempFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMockExamUserQuestionAnswerTemps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      remainQuestionTime
      mockexamansweruserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamUserQuestionAnswerTempMockExamQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMockExamUserQuestionAnswerTempsQueryVariables,
  APITypes.SyncMockExamUserQuestionAnswerTempsQuery
>;
export const mockExamUserQuestionAnswerTempsByMockexamansweruserID = /* GraphQL */ `query MockExamUserQuestionAnswerTempsByMockexamansweruserID(
  $mockexamansweruserID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMockExamUserQuestionAnswerTempFilterInput
  $limit: Int
  $nextToken: String
) {
  mockExamUserQuestionAnswerTempsByMockexamansweruserID(
    mockexamansweruserID: $mockexamansweruserID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      remainQuestionTime
      mockexamansweruserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamUserQuestionAnswerTempMockExamQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MockExamUserQuestionAnswerTempsByMockexamansweruserIDQueryVariables,
  APITypes.MockExamUserQuestionAnswerTempsByMockexamansweruserIDQuery
>;
export const getCommonCode = /* GraphQL */ `query GetCommonCode($id: ID!) {
  getCommonCode(id: $id) {
    id
    sitesID
    parentId
    pattern
    name
    value
    additional
    sortValue
    isUse
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommonCodeQueryVariables,
  APITypes.GetCommonCodeQuery
>;
export const listCommonCodes = /* GraphQL */ `query ListCommonCodes(
  $filter: ModelCommonCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommonCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      parentId
      pattern
      name
      value
      additional
      sortValue
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommonCodesQueryVariables,
  APITypes.ListCommonCodesQuery
>;
export const syncCommonCodes = /* GraphQL */ `query SyncCommonCodes(
  $filter: ModelCommonCodeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCommonCodes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      parentId
      pattern
      name
      value
      additional
      sortValue
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCommonCodesQueryVariables,
  APITypes.SyncCommonCodesQuery
>;
export const listCommonCodeBySortValue = /* GraphQL */ `query ListCommonCodeBySortValue(
  $sitesID: ID!
  $sortValue: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommonCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommonCodeBySortValue(
    sitesID: $sitesID
    sortValue: $sortValue
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      parentId
      pattern
      name
      value
      additional
      sortValue
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommonCodeBySortValueQueryVariables,
  APITypes.ListCommonCodeBySortValueQuery
>;
export const getCommonCodePattern = /* GraphQL */ `query GetCommonCodePattern($id: ID!) {
  getCommonCodePattern(id: $id) {
    id
    sitesID
    pattern
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommonCodePatternQueryVariables,
  APITypes.GetCommonCodePatternQuery
>;
export const listCommonCodePatterns = /* GraphQL */ `query ListCommonCodePatterns(
  $filter: ModelCommonCodePatternFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommonCodePatterns(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      pattern
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommonCodePatternsQueryVariables,
  APITypes.ListCommonCodePatternsQuery
>;
export const syncCommonCodePatterns = /* GraphQL */ `query SyncCommonCodePatterns(
  $filter: ModelCommonCodePatternFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCommonCodePatterns(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      pattern
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCommonCodePatternsQueryVariables,
  APITypes.SyncCommonCodePatternsQuery
>;
export const searchCommonCodePatterns = /* GraphQL */ `query SearchCommonCodePatterns(
  $filter: SearchableCommonCodePatternFilterInput
  $sort: [SearchableCommonCodePatternSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCommonCodePatternAggregationInput]
) {
  searchCommonCodePatterns(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      pattern
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCommonCodePatternsQueryVariables,
  APITypes.SearchCommonCodePatternsQuery
>;
export const getSites = /* GraphQL */ `query GetSites($id: ID!) {
  getSites(id: $id) {
    id
    siteName
    siteCode
    academyCode
    posCode
    metaTitle
    metaDescription
    metaKeyword
    metaImage
    Menus {
      nextToken
      startedAt
      __typename
    }
    Lectures {
      nextToken
      startedAt
      __typename
    }
    Banners {
      nextToken
      startedAt
      __typename
    }
    PopupBanners {
      nextToken
      startedAt
      __typename
    }
    Teachers {
      nextToken
      startedAt
      __typename
    }
    FreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    BestFreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    FreeContentsList {
      nextToken
      startedAt
      __typename
    }
    CommonCodes {
      nextToken
      startedAt
      __typename
    }
    ImageLibraries {
      nextToken
      startedAt
      __typename
    }
    SMSSends {
      nextToken
      startedAt
      __typename
    }
    SMSTemplateMsg {
      nextToken
      startedAt
      __typename
    }
    QuickMenus {
      nextToken
      startedAt
      __typename
    }
    AdminGroup {
      nextToken
      startedAt
      __typename
    }
    EventClasses {
      nextToken
      startedAt
      __typename
    }
    Events {
      nextToken
      startedAt
      __typename
    }
    FullServiceSubjects {
      nextToken
      startedAt
      __typename
    }
    FullServiceExams {
      nextToken
      startedAt
      __typename
    }
    FullServiceDisplayBoards {
      nextToken
      startedAt
      __typename
    }
    TextContents {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSitesQueryVariables, APITypes.GetSitesQuery>;
export const listSites = /* GraphQL */ `query ListSites(
  $filter: ModelSitesFilterInput
  $limit: Int
  $nextToken: String
) {
  listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      siteName
      siteCode
      academyCode
      posCode
      metaTitle
      metaDescription
      metaKeyword
      metaImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSitesQueryVariables, APITypes.ListSitesQuery>;
export const syncSites = /* GraphQL */ `query SyncSites(
  $filter: ModelSitesFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSites(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      siteName
      siteCode
      academyCode
      posCode
      metaTitle
      metaDescription
      metaKeyword
      metaImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncSitesQueryVariables, APITypes.SyncSitesQuery>;
export const getMenus = /* GraphQL */ `query GetMenus($id: ID!) {
  getMenus(id: $id) {
    id
    sitesID
    parentId
    name
    depth
    linkURL
    target
    isBold
    isUse
    isIcon
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMenusQueryVariables, APITypes.GetMenusQuery>;
export const listMenus = /* GraphQL */ `query ListMenus(
  $filter: ModelMenusFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      parentId
      name
      depth
      linkURL
      target
      isBold
      isUse
      isIcon
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMenusQueryVariables, APITypes.ListMenusQuery>;
export const syncMenus = /* GraphQL */ `query SyncMenus(
  $filter: ModelMenusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMenus(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      parentId
      name
      depth
      linkURL
      target
      isBold
      isUse
      isIcon
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncMenusQueryVariables, APITypes.SyncMenusQuery>;
export const listMenusBySortValue = /* GraphQL */ `query ListMenusBySortValue(
  $sitesID: ID!
  $sortValueCreatedAt: ModelMenusBySitesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMenusFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenusBySortValue(
    sitesID: $sitesID
    sortValueCreatedAt: $sortValueCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      parentId
      name
      depth
      linkURL
      target
      isBold
      isUse
      isIcon
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenusBySortValueQueryVariables,
  APITypes.ListMenusBySortValueQuery
>;
export const getJobPostingMain = /* GraphQL */ `query GetJobPostingMain($id: ID!) {
  getJobPostingMain(id: $id) {
    id
    sortValue
    JobPosting {
      id
      jobPostingTitle
      jobPostingTitleSub1
      jobPostingTitleSub2
      jobPostingType
      companyLogo
      jobPostingBeginDate
      jobPostingEndDate
      isDisplayMain
      displayMainSortValue
      firstDisplayItemKey
      JobPostingItemsJson
      cntView
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    jobPostingMainJobPostingId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJobPostingMainQueryVariables,
  APITypes.GetJobPostingMainQuery
>;
export const listJobPostingMains = /* GraphQL */ `query ListJobPostingMains(
  $filter: ModelJobPostingMainFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobPostingMains(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobPostingMainJobPostingId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJobPostingMainsQueryVariables,
  APITypes.ListJobPostingMainsQuery
>;
export const syncJobPostingMains = /* GraphQL */ `query SyncJobPostingMains(
  $filter: ModelJobPostingMainFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncJobPostingMains(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      jobPostingMainJobPostingId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncJobPostingMainsQueryVariables,
  APITypes.SyncJobPostingMainsQuery
>;
export const getJobPosting = /* GraphQL */ `query GetJobPosting($id: ID!) {
  getJobPosting(id: $id) {
    id
    jobPostingTitle
    jobPostingTitleSub1
    jobPostingTitleSub2
    jobPostingType
    companyLogo
    jobPostingBeginDate
    jobPostingEndDate
    isDisplayMain
    displayMainSortValue
    firstDisplayItemKey
    JobPostingItemsJson
    cntView
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetJobPostingQueryVariables,
  APITypes.GetJobPostingQuery
>;
export const listJobPostings = /* GraphQL */ `query ListJobPostings(
  $filter: ModelJobPostingFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobPostings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      jobPostingTitle
      jobPostingTitleSub1
      jobPostingTitleSub2
      jobPostingType
      companyLogo
      jobPostingBeginDate
      jobPostingEndDate
      isDisplayMain
      displayMainSortValue
      firstDisplayItemKey
      JobPostingItemsJson
      cntView
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListJobPostingsQueryVariables,
  APITypes.ListJobPostingsQuery
>;
export const syncJobPostings = /* GraphQL */ `query SyncJobPostings(
  $filter: ModelJobPostingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncJobPostings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      jobPostingTitle
      jobPostingTitleSub1
      jobPostingTitleSub2
      jobPostingType
      companyLogo
      jobPostingBeginDate
      jobPostingEndDate
      isDisplayMain
      displayMainSortValue
      firstDisplayItemKey
      JobPostingItemsJson
      cntView
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncJobPostingsQueryVariables,
  APITypes.SyncJobPostingsQuery
>;
export const searchJobPostings = /* GraphQL */ `query SearchJobPostings(
  $filter: SearchableJobPostingFilterInput
  $sort: [SearchableJobPostingSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableJobPostingAggregationInput]
) {
  searchJobPostings(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      jobPostingTitle
      jobPostingTitleSub1
      jobPostingTitleSub2
      jobPostingType
      companyLogo
      jobPostingBeginDate
      jobPostingEndDate
      isDisplayMain
      displayMainSortValue
      firstDisplayItemKey
      JobPostingItemsJson
      cntView
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchJobPostingsQueryVariables,
  APITypes.SearchJobPostingsQuery
>;
export const getPassSpecificationsStatus = /* GraphQL */ `query GetPassSpecificationsStatus($id: ID!) {
  getPassSpecificationsStatus(id: $id) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    university
    courseCredit
    toeicScore
    toeicSpeakingGrade
    certificationCount
    isOverseasExperience
    externalActivitiesCount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPassSpecificationsStatusQueryVariables,
  APITypes.GetPassSpecificationsStatusQuery
>;
export const listPassSpecificationsStatuses = /* GraphQL */ `query ListPassSpecificationsStatuses(
  $filter: ModelPassSpecificationsStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listPassSpecificationsStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      university
      courseCredit
      toeicScore
      toeicSpeakingGrade
      certificationCount
      isOverseasExperience
      externalActivitiesCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPassSpecificationsStatusesQueryVariables,
  APITypes.ListPassSpecificationsStatusesQuery
>;
export const syncPassSpecificationsStatuses = /* GraphQL */ `query SyncPassSpecificationsStatuses(
  $filter: ModelPassSpecificationsStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPassSpecificationsStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      university
      courseCredit
      toeicScore
      toeicSpeakingGrade
      certificationCount
      isOverseasExperience
      externalActivitiesCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPassSpecificationsStatusesQueryVariables,
  APITypes.SyncPassSpecificationsStatusesQuery
>;
export const passSpecificationsStatusesByPassspecificationsIDAndCreatedAt = /* GraphQL */ `query PassSpecificationsStatusesByPassspecificationsIDAndCreatedAt(
  $passspecificationsID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPassSpecificationsStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  passSpecificationsStatusesByPassspecificationsIDAndCreatedAt(
    passspecificationsID: $passspecificationsID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      university
      courseCredit
      toeicScore
      toeicSpeakingGrade
      certificationCount
      isOverseasExperience
      externalActivitiesCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PassSpecificationsStatusesByPassspecificationsIDAndCreatedAtQueryVariables,
  APITypes.PassSpecificationsStatusesByPassspecificationsIDAndCreatedAtQuery
>;
export const getPassInterview = /* GraphQL */ `query GetPassInterview($id: ID!) {
  getPassInterview(id: $id) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPassInterviewQueryVariables,
  APITypes.GetPassInterviewQuery
>;
export const listPassInterviews = /* GraphQL */ `query ListPassInterviews(
  $filter: ModelPassInterviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listPassInterviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPassInterviewsQueryVariables,
  APITypes.ListPassInterviewsQuery
>;
export const syncPassInterviews = /* GraphQL */ `query SyncPassInterviews(
  $filter: ModelPassInterviewFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPassInterviews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPassInterviewsQueryVariables,
  APITypes.SyncPassInterviewsQuery
>;
export const passInterviewsByPassspecificationsIDAndCreatedAt = /* GraphQL */ `query PassInterviewsByPassspecificationsIDAndCreatedAt(
  $passspecificationsID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPassInterviewFilterInput
  $limit: Int
  $nextToken: String
) {
  passInterviewsByPassspecificationsIDAndCreatedAt(
    passspecificationsID: $passspecificationsID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PassInterviewsByPassspecificationsIDAndCreatedAtQueryVariables,
  APITypes.PassInterviewsByPassspecificationsIDAndCreatedAtQuery
>;
export const getPassPersonalStatement = /* GraphQL */ `query GetPassPersonalStatement($id: ID!) {
  getPassPersonalStatement(id: $id) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPassPersonalStatementQueryVariables,
  APITypes.GetPassPersonalStatementQuery
>;
export const listPassPersonalStatements = /* GraphQL */ `query ListPassPersonalStatements(
  $filter: ModelPassPersonalStatementFilterInput
  $limit: Int
  $nextToken: String
) {
  listPassPersonalStatements(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPassPersonalStatementsQueryVariables,
  APITypes.ListPassPersonalStatementsQuery
>;
export const syncPassPersonalStatements = /* GraphQL */ `query SyncPassPersonalStatements(
  $filter: ModelPassPersonalStatementFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPassPersonalStatements(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPassPersonalStatementsQueryVariables,
  APITypes.SyncPassPersonalStatementsQuery
>;
export const passPersonalStatementsByPassspecificationsIDAndCreatedAt = /* GraphQL */ `query PassPersonalStatementsByPassspecificationsIDAndCreatedAt(
  $passspecificationsID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPassPersonalStatementFilterInput
  $limit: Int
  $nextToken: String
) {
  passPersonalStatementsByPassspecificationsIDAndCreatedAt(
    passspecificationsID: $passspecificationsID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      passspecificationsID
      passDate
      employmentType
      supportJob
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PassPersonalStatementsByPassspecificationsIDAndCreatedAtQueryVariables,
  APITypes.PassPersonalStatementsByPassspecificationsIDAndCreatedAtQuery
>;
export const getPassSpecifications = /* GraphQL */ `query GetPassSpecifications($id: ID!) {
  getPassSpecifications(id: $id) {
    id
    companyName
    companyType
    companyLogo
    sortValue
    PassPersonalStatements {
      nextToken
      startedAt
      __typename
    }
    PassInterviews {
      nextToken
      startedAt
      __typename
    }
    PassSpecificationsStatuses {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPassSpecificationsQueryVariables,
  APITypes.GetPassSpecificationsQuery
>;
export const listPassSpecifications = /* GraphQL */ `query ListPassSpecifications(
  $filter: ModelPassSpecificationsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPassSpecifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      companyName
      companyType
      companyLogo
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPassSpecificationsQueryVariables,
  APITypes.ListPassSpecificationsQuery
>;
export const syncPassSpecifications = /* GraphQL */ `query SyncPassSpecifications(
  $filter: ModelPassSpecificationsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPassSpecifications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      companyName
      companyType
      companyLogo
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPassSpecificationsQueryVariables,
  APITypes.SyncPassSpecificationsQuery
>;
export const searchPassSpecifications = /* GraphQL */ `query SearchPassSpecifications(
  $filter: SearchablePassSpecificationsFilterInput
  $sort: [SearchablePassSpecificationsSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePassSpecificationsAggregationInput]
) {
  searchPassSpecifications(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      companyName
      companyType
      companyLogo
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPassSpecificationsQueryVariables,
  APITypes.SearchPassSpecificationsQuery
>;
export const getLecture = /* GraphQL */ `query GetLecture($id: ID!) {
  getLecture(id: $id) {
    id
    sitesID
    lectureIdx
    lectureName
    teacherName
    lectureImage
    lectureListPointText
    lectureDetailPointText
    shootingTime
    icons
    lectureLink
    eventPCLink
    eventMobileLink
    coursePeriodDescription
    averageLearningTime
    isDisplayCurriculum
    isDisplayTeacherQuestion
    isDisplayReview
    createdAt
    lectureAlerts {
      nextToken
      startedAt
      __typename
    }
    lectureIntroduces {
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLectureQueryVariables,
  APITypes.GetLectureQuery
>;
export const listLectures = /* GraphQL */ `query ListLectures(
  $filter: ModelLectureFilterInput
  $limit: Int
  $nextToken: String
) {
  listLectures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      lectureIdx
      lectureName
      teacherName
      lectureImage
      lectureListPointText
      lectureDetailPointText
      shootingTime
      icons
      lectureLink
      eventPCLink
      eventMobileLink
      coursePeriodDescription
      averageLearningTime
      isDisplayCurriculum
      isDisplayTeacherQuestion
      isDisplayReview
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLecturesQueryVariables,
  APITypes.ListLecturesQuery
>;
export const syncLectures = /* GraphQL */ `query SyncLectures(
  $filter: ModelLectureFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLectures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      lectureIdx
      lectureName
      teacherName
      lectureImage
      lectureListPointText
      lectureDetailPointText
      shootingTime
      icons
      lectureLink
      eventPCLink
      eventMobileLink
      coursePeriodDescription
      averageLearningTime
      isDisplayCurriculum
      isDisplayTeacherQuestion
      isDisplayReview
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLecturesQueryVariables,
  APITypes.SyncLecturesQuery
>;
export const listLectureBySortValue = /* GraphQL */ `query ListLectureBySortValue(
  $sitesID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLectureFilterInput
  $limit: Int
  $nextToken: String
) {
  listLectureBySortValue(
    sitesID: $sitesID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      lectureIdx
      lectureName
      teacherName
      lectureImage
      lectureListPointText
      lectureDetailPointText
      shootingTime
      icons
      lectureLink
      eventPCLink
      eventMobileLink
      coursePeriodDescription
      averageLearningTime
      isDisplayCurriculum
      isDisplayTeacherQuestion
      isDisplayReview
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLectureBySortValueQueryVariables,
  APITypes.ListLectureBySortValueQuery
>;
export const searchLectures = /* GraphQL */ `query SearchLectures(
  $filter: SearchableLectureFilterInput
  $sort: [SearchableLectureSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableLectureAggregationInput]
) {
  searchLectures(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      lectureIdx
      lectureName
      teacherName
      lectureImage
      lectureListPointText
      lectureDetailPointText
      shootingTime
      icons
      lectureLink
      eventPCLink
      eventMobileLink
      coursePeriodDescription
      averageLearningTime
      isDisplayCurriculum
      isDisplayTeacherQuestion
      isDisplayReview
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchLecturesQueryVariables,
  APITypes.SearchLecturesQuery
>;
export const getLectureAlert = /* GraphQL */ `query GetLectureAlert($id: ID!) {
  getLectureAlert(id: $id) {
    id
    lecturesID
    lectureIdx
    isDisplay
    image
    content
    linkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLectureAlertQueryVariables,
  APITypes.GetLectureAlertQuery
>;
export const listLectureAlerts = /* GraphQL */ `query ListLectureAlerts(
  $filter: ModelLectureAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  listLectureAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      lecturesID
      lectureIdx
      isDisplay
      image
      content
      linkURL
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLectureAlertsQueryVariables,
  APITypes.ListLectureAlertsQuery
>;
export const syncLectureAlerts = /* GraphQL */ `query SyncLectureAlerts(
  $filter: ModelLectureAlertFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLectureAlerts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      lecturesID
      lectureIdx
      isDisplay
      image
      content
      linkURL
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLectureAlertsQueryVariables,
  APITypes.SyncLectureAlertsQuery
>;
export const lectureAlertsByLecturesID = /* GraphQL */ `query LectureAlertsByLecturesID(
  $lecturesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLectureAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  lectureAlertsByLecturesID(
    lecturesID: $lecturesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      lecturesID
      lectureIdx
      isDisplay
      image
      content
      linkURL
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LectureAlertsByLecturesIDQueryVariables,
  APITypes.LectureAlertsByLecturesIDQuery
>;
export const getLectureIntroduce = /* GraphQL */ `query GetLectureIntroduce($id: ID!) {
  getLectureIntroduce(id: $id) {
    id
    lecturesID
    lectureIdx
    lectureViewType
    lectureIntroduceImages
    lectureIntroduceText
    lectureIntroduceTextMobile
    teacherViewType
    teacherIntroduceImages
    teacherIntroduceText
    teacherIntroduceTextMobile
    bookViewType
    bookIntroduceImage
    bookIntroduceText
    bookIntroduceTextMobile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLectureIntroduceQueryVariables,
  APITypes.GetLectureIntroduceQuery
>;
export const listLectureIntroduces = /* GraphQL */ `query ListLectureIntroduces(
  $filter: ModelLectureIntroduceFilterInput
  $limit: Int
  $nextToken: String
) {
  listLectureIntroduces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      lecturesID
      lectureIdx
      lectureViewType
      lectureIntroduceImages
      lectureIntroduceText
      lectureIntroduceTextMobile
      teacherViewType
      teacherIntroduceImages
      teacherIntroduceText
      teacherIntroduceTextMobile
      bookViewType
      bookIntroduceImage
      bookIntroduceText
      bookIntroduceTextMobile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLectureIntroducesQueryVariables,
  APITypes.ListLectureIntroducesQuery
>;
export const syncLectureIntroduces = /* GraphQL */ `query SyncLectureIntroduces(
  $filter: ModelLectureIntroduceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLectureIntroduces(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      lecturesID
      lectureIdx
      lectureViewType
      lectureIntroduceImages
      lectureIntroduceText
      lectureIntroduceTextMobile
      teacherViewType
      teacherIntroduceImages
      teacherIntroduceText
      teacherIntroduceTextMobile
      bookViewType
      bookIntroduceImage
      bookIntroduceText
      bookIntroduceTextMobile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLectureIntroducesQueryVariables,
  APITypes.SyncLectureIntroducesQuery
>;
export const lectureIntroducesByLecturesID = /* GraphQL */ `query LectureIntroducesByLecturesID(
  $lecturesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLectureIntroduceFilterInput
  $limit: Int
  $nextToken: String
) {
  lectureIntroducesByLecturesID(
    lecturesID: $lecturesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      lecturesID
      lectureIdx
      lectureViewType
      lectureIntroduceImages
      lectureIntroduceText
      lectureIntroduceTextMobile
      teacherViewType
      teacherIntroduceImages
      teacherIntroduceText
      teacherIntroduceTextMobile
      bookViewType
      bookIntroduceImage
      bookIntroduceText
      bookIntroduceTextMobile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LectureIntroducesByLecturesIDQueryVariables,
  APITypes.LectureIntroducesByLecturesIDQuery
>;
export const getSmartFinderTeacher = /* GraphQL */ `query GetSmartFinderTeacher($id: ID!) {
  getSmartFinderTeacher(id: $id) {
    id
    sitesID
    CategoryItemCCDRoute
    title
    description
    subDescriptionsubTitle
    teacherImage
    teacherLinkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSmartFinderTeacherQueryVariables,
  APITypes.GetSmartFinderTeacherQuery
>;
export const listSmartFinderTeachers = /* GraphQL */ `query ListSmartFinderTeachers(
  $filter: ModelSmartFinderTeacherFilterInput
  $limit: Int
  $nextToken: String
) {
  listSmartFinderTeachers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      CategoryItemCCDRoute
      title
      description
      subDescriptionsubTitle
      teacherImage
      teacherLinkURL
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSmartFinderTeachersQueryVariables,
  APITypes.ListSmartFinderTeachersQuery
>;
export const syncSmartFinderTeachers = /* GraphQL */ `query SyncSmartFinderTeachers(
  $filter: ModelSmartFinderTeacherFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSmartFinderTeachers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      CategoryItemCCDRoute
      title
      description
      subDescriptionsubTitle
      teacherImage
      teacherLinkURL
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSmartFinderTeachersQueryVariables,
  APITypes.SyncSmartFinderTeachersQuery
>;
export const getBanner = /* GraphQL */ `query GetBanner($id: ID!) {
  getBanner(id: $id) {
    id
    sitesID
    bannerClassCodeDepth1
    bannerClassCodeDepth2
    bannerClassCodeDepth3
    bannerClassCodeDepth4
    bannerTypeCode
    title
    sortValue
    description
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    viewHTML
    imageAlt
    deadlineText
    isUseAlert
    bannerTargetCode
    linkURL
    productIdx
    bookId
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    backgroundColor
    utmParam
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBannerQueryVariables, APITypes.GetBannerQuery>;
export const listBanners = /* GraphQL */ `query ListBanners(
  $filter: ModelBannerFilterInput
  $limit: Int
  $nextToken: String
) {
  listBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      bannerClassCodeDepth1
      bannerClassCodeDepth2
      bannerClassCodeDepth3
      bannerClassCodeDepth4
      bannerTypeCode
      title
      sortValue
      description
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      viewHTML
      imageAlt
      deadlineText
      isUseAlert
      bannerTargetCode
      linkURL
      productIdx
      bookId
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      backgroundColor
      utmParam
      isUse
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBannersQueryVariables,
  APITypes.ListBannersQuery
>;
export const syncBanners = /* GraphQL */ `query SyncBanners(
  $filter: ModelBannerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBanners(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      bannerClassCodeDepth1
      bannerClassCodeDepth2
      bannerClassCodeDepth3
      bannerClassCodeDepth4
      bannerTypeCode
      title
      sortValue
      description
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      viewHTML
      imageAlt
      deadlineText
      isUseAlert
      bannerTargetCode
      linkURL
      productIdx
      bookId
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      backgroundColor
      utmParam
      isUse
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBannersQueryVariables,
  APITypes.SyncBannersQuery
>;
export const listBannerBySortValue = /* GraphQL */ `query ListBannerBySortValue(
  $sitesID: ID!
  $sortValueCreatedAt: ModelBannerBySitesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBannerFilterInput
  $limit: Int
  $nextToken: String
) {
  listBannerBySortValue(
    sitesID: $sitesID
    sortValueCreatedAt: $sortValueCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      bannerClassCodeDepth1
      bannerClassCodeDepth2
      bannerClassCodeDepth3
      bannerClassCodeDepth4
      bannerTypeCode
      title
      sortValue
      description
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      viewHTML
      imageAlt
      deadlineText
      isUseAlert
      bannerTargetCode
      linkURL
      productIdx
      bookId
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      backgroundColor
      utmParam
      isUse
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBannerBySortValueQueryVariables,
  APITypes.ListBannerBySortValueQuery
>;
export const searchBanners = /* GraphQL */ `query SearchBanners(
  $filter: SearchableBannerFilterInput
  $sort: [SearchableBannerSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBannerAggregationInput]
) {
  searchBanners(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      bannerClassCodeDepth1
      bannerClassCodeDepth2
      bannerClassCodeDepth3
      bannerClassCodeDepth4
      bannerTypeCode
      title
      sortValue
      description
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      viewHTML
      imageAlt
      deadlineText
      isUseAlert
      bannerTargetCode
      linkURL
      productIdx
      bookId
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      backgroundColor
      utmParam
      isUse
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBannersQueryVariables,
  APITypes.SearchBannersQuery
>;
export const getPopupBanner = /* GraphQL */ `query GetPopupBanner($id: ID!) {
  getPopupBanner(id: $id) {
    id
    sitesID
    title
    bannerFunctionType
    exposureLocation
    isTimeBoard
    isShake
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    displayLocationX
    displayLocationY
    isCalcRelative
    isAlignCenter
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    bannerTargetCode
    mapLinks {
      coords
      target
      linkURL
      linkAlt
      __typename
    }
    linkURL
    sortValue
    isUse
    isDisplayPC
    isDisplayMobile
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPopupBannerQueryVariables,
  APITypes.GetPopupBannerQuery
>;
export const listPopupBanners = /* GraphQL */ `query ListPopupBanners(
  $filter: ModelPopupBannerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPopupBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      title
      bannerFunctionType
      exposureLocation
      isTimeBoard
      isShake
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      displayLocationX
      displayLocationY
      isCalcRelative
      isAlignCenter
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      bannerTargetCode
      linkURL
      sortValue
      isUse
      isDisplayPC
      isDisplayMobile
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPopupBannersQueryVariables,
  APITypes.ListPopupBannersQuery
>;
export const syncPopupBanners = /* GraphQL */ `query SyncPopupBanners(
  $filter: ModelPopupBannerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPopupBanners(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      title
      bannerFunctionType
      exposureLocation
      isTimeBoard
      isShake
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      displayLocationX
      displayLocationY
      isCalcRelative
      isAlignCenter
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      bannerTargetCode
      linkURL
      sortValue
      isUse
      isDisplayPC
      isDisplayMobile
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPopupBannersQueryVariables,
  APITypes.SyncPopupBannersQuery
>;
export const listPopupBannerBySortValue = /* GraphQL */ `query ListPopupBannerBySortValue(
  $sitesID: ID!
  $sortValueCreatedAt: ModelPopupBannerBySitesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPopupBannerFilterInput
  $limit: Int
  $nextToken: String
) {
  listPopupBannerBySortValue(
    sitesID: $sitesID
    sortValueCreatedAt: $sortValueCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      title
      bannerFunctionType
      exposureLocation
      isTimeBoard
      isShake
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      displayLocationX
      displayLocationY
      isCalcRelative
      isAlignCenter
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      bannerTargetCode
      linkURL
      sortValue
      isUse
      isDisplayPC
      isDisplayMobile
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPopupBannerBySortValueQueryVariables,
  APITypes.ListPopupBannerBySortValueQuery
>;
export const searchPopupBanners = /* GraphQL */ `query SearchPopupBanners(
  $filter: SearchablePopupBannerFilterInput
  $sort: [SearchablePopupBannerSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePopupBannerAggregationInput]
) {
  searchPopupBanners(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      title
      bannerFunctionType
      exposureLocation
      isTimeBoard
      isShake
      displayBeginDate
      displayEndDate
      displayBeginDateTime
      displayEndDateTime
      displayLocationX
      displayLocationY
      isCalcRelative
      isAlignCenter
      bannerViewTypeCode
      viewIFrameURL
      viewImageURL
      bannerTargetCode
      linkURL
      sortValue
      isUse
      isDisplayPC
      isDisplayMobile
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPopupBannersQueryVariables,
  APITypes.SearchPopupBannersQuery
>;
export const getTeacher = /* GraphQL */ `query GetTeacher($id: ID!) {
  getTeacher(id: $id) {
    id
    sitesID
    teacherIdx
    teacherListImage
    teacherSubjectLv3
    teacherSubject
    teacherSubjectCategory
    representingLectures
    bestLectures
    curriculums
    lectures
    events
    academyRepresentingLectures
    academyBestLectures
    academyCurriculums
    academyLectures
    academyEvents
    teacherListMainImage
    teacherListImageOn
    teacherListImageOff
    teacherMobileListImageOn
    teacherMobileListImageOff
    teacherIntroduce
    displayTeacherName
    iconText
    description
    lectureListImage
    teacherDetailImage
    teacherMobileDetailImage
    teacherCurriculumImage
    teacherIconImage
    targetScore
    titleTextTop
    titleTextSubject
    otVideoURL
    otVideoThumbnail
    representingVideoURL
    representingVideoThumbnail
    sampleVideoURL
    sampleVideoThumbnail
    reasonText
    lectureReviewVideo {
      video
      thumbnail
      __typename
    }
    curriculumImage
    curriculumContent
    curriculumText
    curriculumURL
    recommandPackage
    recommandPackageMobile
    teacherIntroduceDetailImage
    teacherIntroduceDetailMobileImage
    teacherHighScoreImage
    teacherHighScoreMobileImage
    teacherStepImage
    teacherStepMobileImage
    bestLectureSample {
      title
      url
      thumbnail
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTeacherQueryVariables,
  APITypes.GetTeacherQuery
>;
export const listTeachers = /* GraphQL */ `query ListTeachers(
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeachers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      teacherIdx
      teacherListImage
      teacherSubjectLv3
      teacherSubject
      teacherSubjectCategory
      representingLectures
      bestLectures
      curriculums
      lectures
      events
      academyRepresentingLectures
      academyBestLectures
      academyCurriculums
      academyLectures
      academyEvents
      teacherListMainImage
      teacherListImageOn
      teacherListImageOff
      teacherMobileListImageOn
      teacherMobileListImageOff
      teacherIntroduce
      displayTeacherName
      iconText
      description
      lectureListImage
      teacherDetailImage
      teacherMobileDetailImage
      teacherCurriculumImage
      teacherIconImage
      targetScore
      titleTextTop
      titleTextSubject
      otVideoURL
      otVideoThumbnail
      representingVideoURL
      representingVideoThumbnail
      sampleVideoURL
      sampleVideoThumbnail
      reasonText
      curriculumImage
      curriculumContent
      curriculumText
      curriculumURL
      recommandPackage
      recommandPackageMobile
      teacherIntroduceDetailImage
      teacherIntroduceDetailMobileImage
      teacherHighScoreImage
      teacherHighScoreMobileImage
      teacherStepImage
      teacherStepMobileImage
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeachersQueryVariables,
  APITypes.ListTeachersQuery
>;
export const syncTeachers = /* GraphQL */ `query SyncTeachers(
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTeachers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      teacherIdx
      teacherListImage
      teacherSubjectLv3
      teacherSubject
      teacherSubjectCategory
      representingLectures
      bestLectures
      curriculums
      lectures
      events
      academyRepresentingLectures
      academyBestLectures
      academyCurriculums
      academyLectures
      academyEvents
      teacherListMainImage
      teacherListImageOn
      teacherListImageOff
      teacherMobileListImageOn
      teacherMobileListImageOff
      teacherIntroduce
      displayTeacherName
      iconText
      description
      lectureListImage
      teacherDetailImage
      teacherMobileDetailImage
      teacherCurriculumImage
      teacherIconImage
      targetScore
      titleTextTop
      titleTextSubject
      otVideoURL
      otVideoThumbnail
      representingVideoURL
      representingVideoThumbnail
      sampleVideoURL
      sampleVideoThumbnail
      reasonText
      curriculumImage
      curriculumContent
      curriculumText
      curriculumURL
      recommandPackage
      recommandPackageMobile
      teacherIntroduceDetailImage
      teacherIntroduceDetailMobileImage
      teacherHighScoreImage
      teacherHighScoreMobileImage
      teacherStepImage
      teacherStepMobileImage
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTeachersQueryVariables,
  APITypes.SyncTeachersQuery
>;
export const listTeacherBySortValue = /* GraphQL */ `query ListTeacherBySortValue(
  $sitesID: ID!
  $sortValueCreatedAt: ModelTeacherBySitesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTeacherFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeacherBySortValue(
    sitesID: $sitesID
    sortValueCreatedAt: $sortValueCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      teacherIdx
      teacherListImage
      teacherSubjectLv3
      teacherSubject
      teacherSubjectCategory
      representingLectures
      bestLectures
      curriculums
      lectures
      events
      academyRepresentingLectures
      academyBestLectures
      academyCurriculums
      academyLectures
      academyEvents
      teacherListMainImage
      teacherListImageOn
      teacherListImageOff
      teacherMobileListImageOn
      teacherMobileListImageOff
      teacherIntroduce
      displayTeacherName
      iconText
      description
      lectureListImage
      teacherDetailImage
      teacherMobileDetailImage
      teacherCurriculumImage
      teacherIconImage
      targetScore
      titleTextTop
      titleTextSubject
      otVideoURL
      otVideoThumbnail
      representingVideoURL
      representingVideoThumbnail
      sampleVideoURL
      sampleVideoThumbnail
      reasonText
      curriculumImage
      curriculumContent
      curriculumText
      curriculumURL
      recommandPackage
      recommandPackageMobile
      teacherIntroduceDetailImage
      teacherIntroduceDetailMobileImage
      teacherHighScoreImage
      teacherHighScoreMobileImage
      teacherStepImage
      teacherStepMobileImage
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTeacherBySortValueQueryVariables,
  APITypes.ListTeacherBySortValueQuery
>;
export const searchTeachers = /* GraphQL */ `query SearchTeachers(
  $filter: SearchableTeacherFilterInput
  $sort: [SearchableTeacherSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTeacherAggregationInput]
) {
  searchTeachers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      teacherIdx
      teacherListImage
      teacherSubjectLv3
      teacherSubject
      teacherSubjectCategory
      representingLectures
      bestLectures
      curriculums
      lectures
      events
      academyRepresentingLectures
      academyBestLectures
      academyCurriculums
      academyLectures
      academyEvents
      teacherListMainImage
      teacherListImageOn
      teacherListImageOff
      teacherMobileListImageOn
      teacherMobileListImageOff
      teacherIntroduce
      displayTeacherName
      iconText
      description
      lectureListImage
      teacherDetailImage
      teacherMobileDetailImage
      teacherCurriculumImage
      teacherIconImage
      targetScore
      titleTextTop
      titleTextSubject
      otVideoURL
      otVideoThumbnail
      representingVideoURL
      representingVideoThumbnail
      sampleVideoURL
      sampleVideoThumbnail
      reasonText
      curriculumImage
      curriculumContent
      curriculumText
      curriculumURL
      recommandPackage
      recommandPackageMobile
      teacherIntroduceDetailImage
      teacherIntroduceDetailMobileImage
      teacherHighScoreImage
      teacherHighScoreMobileImage
      teacherStepImage
      teacherStepMobileImage
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTeachersQueryVariables,
  APITypes.SearchTeachersQuery
>;
export const getFreeContentsItemLog = /* GraphQL */ `query GetFreeContentsItemLog($id: ID!) {
  getFreeContentsItemLog(id: $id) {
    id
    memberIdx
    freeContentsID
    freeContentsIconCode
    authenticationTypeCode
    itemName
    freeContentsItemTypeCode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFreeContentsItemLogQueryVariables,
  APITypes.GetFreeContentsItemLogQuery
>;
export const listFreeContentsItemLogs = /* GraphQL */ `query ListFreeContentsItemLogs(
  $filter: ModelFreeContentsItemLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreeContentsItemLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      memberIdx
      freeContentsID
      freeContentsIconCode
      authenticationTypeCode
      itemName
      freeContentsItemTypeCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFreeContentsItemLogsQueryVariables,
  APITypes.ListFreeContentsItemLogsQuery
>;
export const syncFreeContentsItemLogs = /* GraphQL */ `query SyncFreeContentsItemLogs(
  $filter: ModelFreeContentsItemLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFreeContentsItemLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      memberIdx
      freeContentsID
      freeContentsIconCode
      authenticationTypeCode
      itemName
      freeContentsItemTypeCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFreeContentsItemLogsQueryVariables,
  APITypes.SyncFreeContentsItemLogsQuery
>;
export const searchFreeContentsItemLogs = /* GraphQL */ `query SearchFreeContentsItemLogs(
  $filter: SearchableFreeContentsItemLogFilterInput
  $sort: [SearchableFreeContentsItemLogSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFreeContentsItemLogAggregationInput]
) {
  searchFreeContentsItemLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      memberIdx
      freeContentsID
      freeContentsIconCode
      authenticationTypeCode
      itemName
      freeContentsItemTypeCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFreeContentsItemLogsQueryVariables,
  APITypes.SearchFreeContentsItemLogsQuery
>;
export const getFreeContents = /* GraphQL */ `query GetFreeContents($id: ID!) {
  getFreeContents(id: $id) {
    id
    sitesID
    bookId
    bookName
    bookImg
    isPublic
    showLabelCode
    difficultyCode
    purchaseURL
    videoLessonURL
    fieldLessonURL
    authentications {
      authenticationTypeCode
      authenticationValue
      __typename
    }
    freeContentsItems {
      freeContentsIconCode
      authenticationTypeCode
      itemName
      introduceText
      alertText
      freeContentsItemTypeCode
      freeContentsItem
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFreeContentsQueryVariables,
  APITypes.GetFreeContentsQuery
>;
export const listFreeContents = /* GraphQL */ `query ListFreeContents(
  $filter: ModelFreeContentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreeContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      bookId
      bookName
      bookImg
      isPublic
      showLabelCode
      difficultyCode
      purchaseURL
      videoLessonURL
      fieldLessonURL
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFreeContentsQueryVariables,
  APITypes.ListFreeContentsQuery
>;
export const syncFreeContents = /* GraphQL */ `query SyncFreeContents(
  $filter: ModelFreeContentsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFreeContents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      bookId
      bookName
      bookImg
      isPublic
      showLabelCode
      difficultyCode
      purchaseURL
      videoLessonURL
      fieldLessonURL
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFreeContentsQueryVariables,
  APITypes.SyncFreeContentsQuery
>;
export const listFreeContentsBySortValue = /* GraphQL */ `query ListFreeContentsBySortValue(
  $sitesID: ID!
  $sortValueCreatedAt: ModelFreeContentsBySitesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFreeContentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreeContentsBySortValue(
    sitesID: $sitesID
    sortValueCreatedAt: $sortValueCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      bookId
      bookName
      bookImg
      isPublic
      showLabelCode
      difficultyCode
      purchaseURL
      videoLessonURL
      fieldLessonURL
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFreeContentsBySortValueQueryVariables,
  APITypes.ListFreeContentsBySortValueQuery
>;
export const searchFreeContents = /* GraphQL */ `query SearchFreeContents(
  $filter: SearchableFreeContentsFilterInput
  $sort: [SearchableFreeContentsSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFreeContentsAggregationInput]
) {
  searchFreeContents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      bookId
      bookName
      bookImg
      isPublic
      showLabelCode
      difficultyCode
      purchaseURL
      videoLessonURL
      fieldLessonURL
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFreeContentsQueryVariables,
  APITypes.SearchFreeContentsQuery
>;
export const getBestFreeLectures = /* GraphQL */ `query GetBestFreeLectures($id: ID!) {
  getBestFreeLectures(id: $id) {
    id
    sitesID
    title
    description
    freeLectureImage
    videoTypeCode
    videoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBestFreeLecturesQueryVariables,
  APITypes.GetBestFreeLecturesQuery
>;
export const listBestFreeLectures = /* GraphQL */ `query ListBestFreeLectures(
  $filter: ModelBestFreeLecturesFilterInput
  $limit: Int
  $nextToken: String
) {
  listBestFreeLectures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      title
      description
      freeLectureImage
      videoTypeCode
      videoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBestFreeLecturesQueryVariables,
  APITypes.ListBestFreeLecturesQuery
>;
export const syncBestFreeLectures = /* GraphQL */ `query SyncBestFreeLectures(
  $filter: ModelBestFreeLecturesFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBestFreeLectures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      title
      description
      freeLectureImage
      videoTypeCode
      videoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBestFreeLecturesQueryVariables,
  APITypes.SyncBestFreeLecturesQuery
>;
export const listBestFreeLecturesBySortValue = /* GraphQL */ `query ListBestFreeLecturesBySortValue(
  $sitesID: ID!
  $sortValueCreatedAt: ModelBestFreeLecturesBySitesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBestFreeLecturesFilterInput
  $limit: Int
  $nextToken: String
) {
  listBestFreeLecturesBySortValue(
    sitesID: $sitesID
    sortValueCreatedAt: $sortValueCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      title
      description
      freeLectureImage
      videoTypeCode
      videoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBestFreeLecturesBySortValueQueryVariables,
  APITypes.ListBestFreeLecturesBySortValueQuery
>;
export const searchBestFreeLectures = /* GraphQL */ `query SearchBestFreeLectures(
  $filter: SearchableBestFreeLecturesFilterInput
  $sort: [SearchableBestFreeLecturesSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBestFreeLecturesAggregationInput]
) {
  searchBestFreeLectures(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      title
      description
      freeLectureImage
      videoTypeCode
      videoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBestFreeLecturesQueryVariables,
  APITypes.SearchBestFreeLecturesQuery
>;
export const getFreeLectures = /* GraphQL */ `query GetFreeLectures($id: ID!) {
  getFreeLectures(id: $id) {
    id
    sitesID
    title
    description
    thumbnail
    freeLectureClassCodeDepth1
    freeLectureClassCodeDepth2
    freeLectureClassCodeDepth3
    freeLectureClassCodeDepth4
    videoTypeCode
    videoURL
    videoTimeHour
    videoTimeMinute
    videoTimeSecond
    teacherName
    lectureInfoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFreeLecturesQueryVariables,
  APITypes.GetFreeLecturesQuery
>;
export const listFreeLectures = /* GraphQL */ `query ListFreeLectures(
  $filter: ModelFreeLecturesFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreeLectures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      title
      description
      thumbnail
      freeLectureClassCodeDepth1
      freeLectureClassCodeDepth2
      freeLectureClassCodeDepth3
      freeLectureClassCodeDepth4
      videoTypeCode
      videoURL
      videoTimeHour
      videoTimeMinute
      videoTimeSecond
      teacherName
      lectureInfoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFreeLecturesQueryVariables,
  APITypes.ListFreeLecturesQuery
>;
export const syncFreeLectures = /* GraphQL */ `query SyncFreeLectures(
  $filter: ModelFreeLecturesFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFreeLectures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      title
      description
      thumbnail
      freeLectureClassCodeDepth1
      freeLectureClassCodeDepth2
      freeLectureClassCodeDepth3
      freeLectureClassCodeDepth4
      videoTypeCode
      videoURL
      videoTimeHour
      videoTimeMinute
      videoTimeSecond
      teacherName
      lectureInfoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFreeLecturesQueryVariables,
  APITypes.SyncFreeLecturesQuery
>;
export const listFreeLecturesBySortValue = /* GraphQL */ `query ListFreeLecturesBySortValue(
  $sitesID: ID!
  $sortValueCreatedAt: ModelFreeLecturesBySitesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFreeLecturesFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreeLecturesBySortValue(
    sitesID: $sitesID
    sortValueCreatedAt: $sortValueCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      title
      description
      thumbnail
      freeLectureClassCodeDepth1
      freeLectureClassCodeDepth2
      freeLectureClassCodeDepth3
      freeLectureClassCodeDepth4
      videoTypeCode
      videoURL
      videoTimeHour
      videoTimeMinute
      videoTimeSecond
      teacherName
      lectureInfoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFreeLecturesBySortValueQueryVariables,
  APITypes.ListFreeLecturesBySortValueQuery
>;
export const searchFreeLectures = /* GraphQL */ `query SearchFreeLectures(
  $filter: SearchableFreeLecturesFilterInput
  $sort: [SearchableFreeLecturesSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFreeLecturesAggregationInput]
) {
  searchFreeLectures(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      title
      description
      thumbnail
      freeLectureClassCodeDepth1
      freeLectureClassCodeDepth2
      freeLectureClassCodeDepth3
      freeLectureClassCodeDepth4
      videoTypeCode
      videoURL
      videoTimeHour
      videoTimeMinute
      videoTimeSecond
      teacherName
      lectureInfoURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFreeLecturesQueryVariables,
  APITypes.SearchFreeLecturesQuery
>;
export const getImageLibrary = /* GraphQL */ `query GetImageLibrary($id: ID!) {
  getImageLibrary(id: $id) {
    id
    sitesID
    imageURL
    resolution
    size
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetImageLibraryQueryVariables,
  APITypes.GetImageLibraryQuery
>;
export const listImageLibraries = /* GraphQL */ `query ListImageLibraries(
  $filter: ModelImageLibraryFilterInput
  $limit: Int
  $nextToken: String
) {
  listImageLibraries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      imageURL
      resolution
      size
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImageLibrariesQueryVariables,
  APITypes.ListImageLibrariesQuery
>;
export const syncImageLibraries = /* GraphQL */ `query SyncImageLibraries(
  $filter: ModelImageLibraryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncImageLibraries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      imageURL
      resolution
      size
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncImageLibrariesQueryVariables,
  APITypes.SyncImageLibrariesQuery
>;
export const listImageLibraryBySortValue = /* GraphQL */ `query ListImageLibraryBySortValue(
  $sitesID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelImageLibraryFilterInput
  $limit: Int
  $nextToken: String
) {
  listImageLibraryBySortValue(
    sitesID: $sitesID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      imageURL
      resolution
      size
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImageLibraryBySortValueQueryVariables,
  APITypes.ListImageLibraryBySortValueQuery
>;
export const searchImageLibraries = /* GraphQL */ `query SearchImageLibraries(
  $filter: SearchableImageLibraryFilterInput
  $sort: [SearchableImageLibrarySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableImageLibraryAggregationInput]
) {
  searchImageLibraries(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      imageURL
      resolution
      size
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchImageLibrariesQueryVariables,
  APITypes.SearchImageLibrariesQuery
>;
export const getBookIntroduce = /* GraphQL */ `query GetBookIntroduce($id: ID!) {
  getBookIntroduce(id: $id) {
    id
    sitesID
    bookId
    content
    isIntroduceUse
    bookLecture
    withBuyBooks
    bookInfos {
      title
      content
      mobileContent
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBookIntroduceQueryVariables,
  APITypes.GetBookIntroduceQuery
>;
export const listBookIntroduces = /* GraphQL */ `query ListBookIntroduces(
  $filter: ModelBookIntroduceFilterInput
  $limit: Int
  $nextToken: String
) {
  listBookIntroduces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      bookId
      content
      isIntroduceUse
      bookLecture
      withBuyBooks
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBookIntroducesQueryVariables,
  APITypes.ListBookIntroducesQuery
>;
export const syncBookIntroduces = /* GraphQL */ `query SyncBookIntroduces(
  $filter: ModelBookIntroduceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBookIntroduces(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      bookId
      content
      isIntroduceUse
      bookLecture
      withBuyBooks
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBookIntroducesQueryVariables,
  APITypes.SyncBookIntroducesQuery
>;
export const searchBookIntroduces = /* GraphQL */ `query SearchBookIntroduces(
  $filter: SearchableBookIntroduceFilterInput
  $sort: [SearchableBookIntroduceSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBookIntroduceAggregationInput]
) {
  searchBookIntroduces(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      bookId
      content
      isIntroduceUse
      bookLecture
      withBuyBooks
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBookIntroducesQueryVariables,
  APITypes.SearchBookIntroducesQuery
>;
export const getBoardType = /* GraphQL */ `query GetBoardType($id: ID!) {
  getBoardType(id: $id) {
    id
    sitesID
    typeCode
    typeName
    typeCategoryPattern
    template
    isUseFile
    isUseLecture
    isUseTeacher
    isUseSecret
    isUseComment
    isUseScore
    isUseCategory
    isUseThumbnail
    isUseVideo
    isUseLink
    isOnlyAdminWrite
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBoardTypeQueryVariables,
  APITypes.GetBoardTypeQuery
>;
export const listBoardTypes = /* GraphQL */ `query ListBoardTypes(
  $filter: ModelBoardTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listBoardTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      typeCode
      typeName
      typeCategoryPattern
      template
      isUseFile
      isUseLecture
      isUseTeacher
      isUseSecret
      isUseComment
      isUseScore
      isUseCategory
      isUseThumbnail
      isUseVideo
      isUseLink
      isOnlyAdminWrite
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBoardTypesQueryVariables,
  APITypes.ListBoardTypesQuery
>;
export const syncBoardTypes = /* GraphQL */ `query SyncBoardTypes(
  $filter: ModelBoardTypeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBoardTypes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      typeCode
      typeName
      typeCategoryPattern
      template
      isUseFile
      isUseLecture
      isUseTeacher
      isUseSecret
      isUseComment
      isUseScore
      isUseCategory
      isUseThumbnail
      isUseVideo
      isUseLink
      isOnlyAdminWrite
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBoardTypesQueryVariables,
  APITypes.SyncBoardTypesQuery
>;
export const listBoardTypeBySortValue = /* GraphQL */ `query ListBoardTypeBySortValue(
  $sitesID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBoardTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listBoardTypeBySortValue(
    sitesID: $sitesID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      typeCode
      typeName
      typeCategoryPattern
      template
      isUseFile
      isUseLecture
      isUseTeacher
      isUseSecret
      isUseComment
      isUseScore
      isUseCategory
      isUseThumbnail
      isUseVideo
      isUseLink
      isOnlyAdminWrite
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBoardTypeBySortValueQueryVariables,
  APITypes.ListBoardTypeBySortValueQuery
>;
export const getBoard = /* GraphQL */ `query GetBoard($id: ID!) {
  getBoard(id: $id) {
    id
    sitesID
    typeCode
    categoryCode
    businessName
    businessPosition
    description
    lectureIdx
    productIdx
    bookId
    teacherIdx
    title
    content
    files {
      fileName
      size
      url
      regDatetime
      __typename
    }
    thumbnail
    videoURL
    linkImage
    linkURL
    location
    viewCount
    score
    isDisplay
    isBest
    isNotice
    sortValue
    comments {
      nextToken
      startedAt
      __typename
    }
    answer
    answerMemberIdx
    answerMemberId
    answerMemberName
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBoardQueryVariables, APITypes.GetBoardQuery>;
export const listBoards = /* GraphQL */ `query ListBoards(
  $filter: ModelBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      typeCode
      categoryCode
      businessName
      businessPosition
      description
      lectureIdx
      productIdx
      bookId
      teacherIdx
      title
      content
      thumbnail
      videoURL
      linkImage
      linkURL
      location
      viewCount
      score
      isDisplay
      isBest
      isNotice
      sortValue
      answer
      answerMemberIdx
      answerMemberId
      answerMemberName
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBoardsQueryVariables,
  APITypes.ListBoardsQuery
>;
export const syncBoards = /* GraphQL */ `query SyncBoards(
  $filter: ModelBoardFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBoards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      typeCode
      categoryCode
      businessName
      businessPosition
      description
      lectureIdx
      productIdx
      bookId
      teacherIdx
      title
      content
      thumbnail
      videoURL
      linkImage
      linkURL
      location
      viewCount
      score
      isDisplay
      isBest
      isNotice
      sortValue
      answer
      answerMemberIdx
      answerMemberId
      answerMemberName
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBoardsQueryVariables,
  APITypes.SyncBoardsQuery
>;
export const listBoardBySortValue = /* GraphQL */ `query ListBoardBySortValue(
  $sitesID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listBoardBySortValue(
    sitesID: $sitesID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      typeCode
      categoryCode
      businessName
      businessPosition
      description
      lectureIdx
      productIdx
      bookId
      teacherIdx
      title
      content
      thumbnail
      videoURL
      linkImage
      linkURL
      location
      viewCount
      score
      isDisplay
      isBest
      isNotice
      sortValue
      answer
      answerMemberIdx
      answerMemberId
      answerMemberName
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBoardBySortValueQueryVariables,
  APITypes.ListBoardBySortValueQuery
>;
export const searchBoards = /* GraphQL */ `query SearchBoards(
  $filter: SearchableBoardFilterInput
  $sort: [SearchableBoardSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableBoardAggregationInput]
) {
  searchBoards(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      typeCode
      categoryCode
      businessName
      businessPosition
      description
      lectureIdx
      productIdx
      bookId
      teacherIdx
      title
      content
      thumbnail
      videoURL
      linkImage
      linkURL
      location
      viewCount
      score
      isDisplay
      isBest
      isNotice
      sortValue
      answer
      answerMemberIdx
      answerMemberId
      answerMemberName
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchBoardsQueryVariables,
  APITypes.SearchBoardsQuery
>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    sitesID
    boardsID
    parentId
    content
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      boardsID
      parentId
      content
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const syncComments = /* GraphQL */ `query SyncComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncComments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      boardsID
      parentId
      content
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCommentsQueryVariables,
  APITypes.SyncCommentsQuery
>;
export const listCommentBySortValue = /* GraphQL */ `query ListCommentBySortValue(
  $boardsID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommentBySortValue(
    boardsID: $boardsID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      boardsID
      parentId
      content
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentBySortValueQueryVariables,
  APITypes.ListCommentBySortValueQuery
>;
export const searchComments = /* GraphQL */ `query SearchComments(
  $filter: SearchableCommentFilterInput
  $sort: [SearchableCommentSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableCommentAggregationInput]
) {
  searchComments(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      boardsID
      parentId
      content
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCommentsQueryVariables,
  APITypes.SearchCommentsQuery
>;
export const getAdmin = /* GraphQL */ `query GetAdmin($id: ID!) {
  getAdmin(id: $id) {
    id
    memberIdx
    memberId
    memberName
    memberPw
    memberState
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAdminQueryVariables, APITypes.GetAdminQuery>;
export const listAdmins = /* GraphQL */ `query ListAdmins(
  $filter: ModelAdminFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      memberIdx
      memberId
      memberName
      memberPw
      memberState
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminsQueryVariables,
  APITypes.ListAdminsQuery
>;
export const syncAdmins = /* GraphQL */ `query SyncAdmins(
  $filter: ModelAdminFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdmins(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      memberIdx
      memberId
      memberName
      memberPw
      memberState
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminsQueryVariables,
  APITypes.SyncAdminsQuery
>;
export const searchAdmins = /* GraphQL */ `query SearchAdmins(
  $filter: SearchableAdminFilterInput
  $sort: [SearchableAdminSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableAdminAggregationInput]
) {
  searchAdmins(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      memberIdx
      memberId
      memberName
      memberPw
      memberState
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchAdminsQueryVariables,
  APITypes.SearchAdminsQuery
>;
export const getAdminGroup = /* GraphQL */ `query GetAdminGroup($id: ID!) {
  getAdminGroup(id: $id) {
    id
    sitesID
    groupName
    intranetMenuCode
    ismsLevel
    menuCode
    Site {
      id
      siteName
      siteCode
      academyCode
      posCode
      metaTitle
      metaDescription
      metaKeyword
      metaImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminGroupQueryVariables,
  APITypes.GetAdminGroupQuery
>;
export const listAdminGroups = /* GraphQL */ `query ListAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      groupName
      intranetMenuCode
      ismsLevel
      menuCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminGroupsQueryVariables,
  APITypes.ListAdminGroupsQuery
>;
export const syncAdminGroups = /* GraphQL */ `query SyncAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      groupName
      intranetMenuCode
      ismsLevel
      menuCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminGroupsQueryVariables,
  APITypes.SyncAdminGroupsQuery
>;
export const adminGroupsBySitesID = /* GraphQL */ `query AdminGroupsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  adminGroupsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      groupName
      intranetMenuCode
      ismsLevel
      menuCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdminGroupsBySitesIDQueryVariables,
  APITypes.AdminGroupsBySitesIDQuery
>;
export const searchAdminGroups = /* GraphQL */ `query SearchAdminGroups(
  $filter: SearchableAdminGroupFilterInput
  $sort: [SearchableAdminGroupSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableAdminGroupAggregationInput]
) {
  searchAdminGroups(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      groupName
      intranetMenuCode
      ismsLevel
      menuCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchAdminGroupsQueryVariables,
  APITypes.SearchAdminGroupsQuery
>;
export const getAdminLoginLog = /* GraphQL */ `query GetAdminLoginLog($id: ID!) {
  getAdminLoginLog(id: $id) {
    id
    memberIdx
    memberId
    ip
    groupName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminLoginLogQueryVariables,
  APITypes.GetAdminLoginLogQuery
>;
export const listAdminLoginLogs = /* GraphQL */ `query ListAdminLoginLogs(
  $filter: ModelAdminLoginLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminLoginLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      memberIdx
      memberId
      ip
      groupName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminLoginLogsQueryVariables,
  APITypes.ListAdminLoginLogsQuery
>;
export const syncAdminLoginLogs = /* GraphQL */ `query SyncAdminLoginLogs(
  $filter: ModelAdminLoginLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminLoginLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      memberIdx
      memberId
      ip
      groupName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminLoginLogsQueryVariables,
  APITypes.SyncAdminLoginLogsQuery
>;
export const searchAdminLoginLogs = /* GraphQL */ `query SearchAdminLoginLogs(
  $filter: SearchableAdminLoginLogFilterInput
  $sort: [SearchableAdminLoginLogSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableAdminLoginLogAggregationInput]
) {
  searchAdminLoginLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      memberIdx
      memberId
      ip
      groupName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchAdminLoginLogsQueryVariables,
  APITypes.SearchAdminLoginLogsQuery
>;
export const getAdminMenus = /* GraphQL */ `query GetAdminMenus($id: ID!) {
  getAdminMenus(id: $id) {
    id
    sitesID
    groupCode
    menuName
    parentId
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminMenusQueryVariables,
  APITypes.GetAdminMenusQuery
>;
export const listAdminMenus = /* GraphQL */ `query ListAdminMenus(
  $filter: ModelAdminMenusFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      groupCode
      menuName
      parentId
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminMenusQueryVariables,
  APITypes.ListAdminMenusQuery
>;
export const syncAdminMenus = /* GraphQL */ `query SyncAdminMenus(
  $filter: ModelAdminMenusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminMenus(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      groupCode
      menuName
      parentId
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminMenusQueryVariables,
  APITypes.SyncAdminMenusQuery
>;
export const getQuickMenu = /* GraphQL */ `query GetQuickMenu($id: ID!) {
  getQuickMenu(id: $id) {
    id
    sitesID
    menuName
    icon
    linkURL
    target
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuickMenuQueryVariables,
  APITypes.GetQuickMenuQuery
>;
export const listQuickMenus = /* GraphQL */ `query ListQuickMenus(
  $filter: ModelQuickMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuickMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      menuName
      icon
      linkURL
      target
      isUse
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuickMenusQueryVariables,
  APITypes.ListQuickMenusQuery
>;
export const syncQuickMenus = /* GraphQL */ `query SyncQuickMenus(
  $filter: ModelQuickMenuFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncQuickMenus(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      menuName
      icon
      linkURL
      target
      isUse
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncQuickMenusQueryVariables,
  APITypes.SyncQuickMenusQuery
>;
export const listQuickMenuBySortValue = /* GraphQL */ `query ListQuickMenuBySortValue(
  $sitesID: ID!
  $sortValue: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQuickMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuickMenuBySortValue(
    sitesID: $sitesID
    sortValue: $sortValue
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      menuName
      icon
      linkURL
      target
      isUse
      regMemberIdx
      regMemberId
      regMemberName
      modifyMemberIdx
      modifyMemberId
      modifyMemberName
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuickMenuBySortValueQueryVariables,
  APITypes.ListQuickMenuBySortValueQuery
>;
export const getEventClass = /* GraphQL */ `query GetEventClass($id: ID!) {
  getEventClass(id: $id) {
    id
    sitesID
    mainCategoryClassCode
    mainCategoryClassName
    subCategoryClassCode
    subCategoryClassName
    ableFunctionCodes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventClassQueryVariables,
  APITypes.GetEventClassQuery
>;
export const listEventClasses = /* GraphQL */ `query ListEventClasses(
  $filter: ModelEventClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      mainCategoryClassCode
      mainCategoryClassName
      subCategoryClassCode
      subCategoryClassName
      ableFunctionCodes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventClassesQueryVariables,
  APITypes.ListEventClassesQuery
>;
export const syncEventClasses = /* GraphQL */ `query SyncEventClasses(
  $filter: ModelEventClassFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEventClasses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      mainCategoryClassCode
      mainCategoryClassName
      subCategoryClassCode
      subCategoryClassName
      ableFunctionCodes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEventClassesQueryVariables,
  APITypes.SyncEventClassesQuery
>;
export const listEventClassBySortValue = /* GraphQL */ `query ListEventClassBySortValue(
  $sitesID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventClassBySortValue(
    sitesID: $sitesID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      mainCategoryClassCode
      mainCategoryClassName
      subCategoryClassCode
      subCategoryClassName
      ableFunctionCodes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventClassBySortValueQueryVariables,
  APITypes.ListEventClassBySortValueQuery
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    sitesID
    mainCategoryClassCode
    subCategoryClassCode
    eventCode
    eventName
    eventURL
    ogImageType
    ogImage
    ogKeyword
    ogDescription
    lectures
    quickBanner
    topBanner
    topLineBanner
    footerBanner
    footerLineBanner
    isFooterFix
    eventVideos {
      videoNo
      videoTypeCode
      videoURL
      videoTitle
      isClickPlay
      thumbnailType
      thumbnail
      __typename
    }
    eventBenefits {
      benefitNo
      benefitTypeCode
      benefitValue
      benefitTitle
      __typename
    }
    scheduleImage
    lectureLists
    freepassLists {
      categoryName
      __typename
    }
    isUseTimer
    isTimerFixed
    spreadImage
    eventMessageSchedule {
      eventKey
      templateId
      scheduleBeginDate
      scheduleEndDate
      scheduleTime
      __typename
    }
    eventCardinals {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      __typename
    }
    eventInstructions {
      InstructionNumber
      InstructionTitle
      InstructionContent
      __typename
    }
    eventAttributes {
      AttributeId
      AttributeName
      AttributeDescription
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      mainCategoryClassCode
      subCategoryClassCode
      eventCode
      eventName
      eventURL
      ogImageType
      ogImage
      ogKeyword
      ogDescription
      lectures
      quickBanner
      topBanner
      topLineBanner
      footerBanner
      footerLineBanner
      isFooterFix
      scheduleImage
      lectureLists
      isUseTimer
      isTimerFixed
      spreadImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const syncEvents = /* GraphQL */ `query SyncEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEvents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      mainCategoryClassCode
      subCategoryClassCode
      eventCode
      eventName
      eventURL
      ogImageType
      ogImage
      ogKeyword
      ogDescription
      lectures
      quickBanner
      topBanner
      topLineBanner
      footerBanner
      footerLineBanner
      isFooterFix
      scheduleImage
      lectureLists
      isUseTimer
      isTimerFixed
      spreadImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEventsQueryVariables,
  APITypes.SyncEventsQuery
>;
export const eventsBySitesID = /* GraphQL */ `query EventsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      mainCategoryClassCode
      subCategoryClassCode
      eventCode
      eventName
      eventURL
      ogImageType
      ogImage
      ogKeyword
      ogDescription
      lectures
      quickBanner
      topBanner
      topLineBanner
      footerBanner
      footerLineBanner
      isFooterFix
      scheduleImage
      lectureLists
      isUseTimer
      isTimerFixed
      spreadImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EventsBySitesIDQueryVariables,
  APITypes.EventsBySitesIDQuery
>;
export const searchEvents = /* GraphQL */ `query SearchEvents(
  $filter: SearchableEventFilterInput
  $sort: [SearchableEventSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableEventAggregationInput]
) {
  searchEvents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      mainCategoryClassCode
      subCategoryClassCode
      eventCode
      eventName
      eventURL
      ogImageType
      ogImage
      ogKeyword
      ogDescription
      lectures
      quickBanner
      topBanner
      topLineBanner
      footerBanner
      footerLineBanner
      isFooterFix
      scheduleImage
      lectureLists
      isUseTimer
      isTimerFixed
      spreadImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEventsQueryVariables,
  APITypes.SearchEventsQuery
>;
export const getEventLog = /* GraphQL */ `query GetEventLog($id: ID!) {
  getEventLog(id: $id) {
    id
    sitesID
    eventCode
    memberIdx
    memberId
    userInfo
    key
    value
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventLogQueryVariables,
  APITypes.GetEventLogQuery
>;
export const listEventLogs = /* GraphQL */ `query ListEventLogs(
  $filter: ModelEventLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      eventCode
      memberIdx
      memberId
      userInfo
      key
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventLogsQueryVariables,
  APITypes.ListEventLogsQuery
>;
export const syncEventLogs = /* GraphQL */ `query SyncEventLogs(
  $filter: ModelEventLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEventLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      eventCode
      memberIdx
      memberId
      userInfo
      key
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEventLogsQueryVariables,
  APITypes.SyncEventLogsQuery
>;
export const searchEventLogs = /* GraphQL */ `query SearchEventLogs(
  $filter: SearchableEventLogFilterInput
  $sort: [SearchableEventLogSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableEventLogAggregationInput]
) {
  searchEventLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      eventCode
      memberIdx
      memberId
      userInfo
      key
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEventLogsQueryVariables,
  APITypes.SearchEventLogsQuery
>;
export const getPassersList = /* GraphQL */ `query GetPassersList($id: ID!) {
  getPassersList(id: $id) {
    id
    sitesID
    passersCategory
    passersType
    companyName
    companyLogo
    passersName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPassersListQueryVariables,
  APITypes.GetPassersListQuery
>;
export const listPassersLists = /* GraphQL */ `query ListPassersLists(
  $filter: ModelPassersListFilterInput
  $limit: Int
  $nextToken: String
) {
  listPassersLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      passersCategory
      passersType
      companyName
      companyLogo
      passersName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPassersListsQueryVariables,
  APITypes.ListPassersListsQuery
>;
export const syncPassersLists = /* GraphQL */ `query SyncPassersLists(
  $filter: ModelPassersListFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPassersLists(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      passersCategory
      passersType
      companyName
      companyLogo
      passersName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPassersListsQueryVariables,
  APITypes.SyncPassersListsQuery
>;
export const searchPassersLists = /* GraphQL */ `query SearchPassersLists(
  $filter: SearchablePassersListFilterInput
  $sort: [SearchablePassersListSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePassersListAggregationInput]
) {
  searchPassersLists(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      passersCategory
      passersType
      companyName
      companyLogo
      passersName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPassersListsQueryVariables,
  APITypes.SearchPassersListsQuery
>;
export const getTestTable = /* GraphQL */ `query GetTestTable($id: ID!) {
  getTestTable(id: $id) {
    id
    NumberData
    StringData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestTableQueryVariables,
  APITypes.GetTestTableQuery
>;
export const listTestTables = /* GraphQL */ `query ListTestTables(
  $filter: ModelTestTableFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      NumberData
      StringData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestTablesQueryVariables,
  APITypes.ListTestTablesQuery
>;
export const syncTestTables = /* GraphQL */ `query SyncTestTables(
  $filter: ModelTestTableFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTestTables(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      NumberData
      StringData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTestTablesQueryVariables,
  APITypes.SyncTestTablesQuery
>;
export const searchTestTables = /* GraphQL */ `query SearchTestTables(
  $filter: SearchableTestTableFilterInput
  $sort: [SearchableTestTableSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTestTableAggregationInput]
) {
  searchTestTables(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      NumberData
      StringData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTestTablesQueryVariables,
  APITypes.SearchTestTablesQuery
>;
export const getDDaySchedule = /* GraphQL */ `query GetDDaySchedule($id: ID!) {
  getDDaySchedule(id: $id) {
    id
    sitesID
    title
    description
    ddayTypeCode
    ddayDate
    ddayBeginDate
    ddayEndDate
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDDayScheduleQueryVariables,
  APITypes.GetDDayScheduleQuery
>;
export const listDDaySchedules = /* GraphQL */ `query ListDDaySchedules(
  $filter: ModelDDayScheduleFilterInput
  $limit: Int
  $nextToken: String
) {
  listDDaySchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      title
      description
      ddayTypeCode
      ddayDate
      ddayBeginDate
      ddayEndDate
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDDaySchedulesQueryVariables,
  APITypes.ListDDaySchedulesQuery
>;
export const syncDDaySchedules = /* GraphQL */ `query SyncDDaySchedules(
  $filter: ModelDDayScheduleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDDaySchedules(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      title
      description
      ddayTypeCode
      ddayDate
      ddayBeginDate
      ddayEndDate
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDDaySchedulesQueryVariables,
  APITypes.SyncDDaySchedulesQuery
>;
export const dDaySchedulesBySitesID = /* GraphQL */ `query DDaySchedulesBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDDayScheduleFilterInput
  $limit: Int
  $nextToken: String
) {
  dDaySchedulesBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      title
      description
      ddayTypeCode
      ddayDate
      ddayBeginDate
      ddayEndDate
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DDaySchedulesBySitesIDQueryVariables,
  APITypes.DDaySchedulesBySitesIDQuery
>;
export const searchDDaySchedules = /* GraphQL */ `query SearchDDaySchedules(
  $filter: SearchableDDayScheduleFilterInput
  $sort: [SearchableDDayScheduleSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDDayScheduleAggregationInput]
) {
  searchDDaySchedules(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      title
      description
      ddayTypeCode
      ddayDate
      ddayBeginDate
      ddayEndDate
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDDaySchedulesQueryVariables,
  APITypes.SearchDDaySchedulesQuery
>;
export const getFullServiceSubject = /* GraphQL */ `query GetFullServiceSubject($id: ID!) {
  getFullServiceSubject(id: $id) {
    id
    sitesID
    subjectName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceSubjectQueryVariables,
  APITypes.GetFullServiceSubjectQuery
>;
export const listFullServiceSubjects = /* GraphQL */ `query ListFullServiceSubjects(
  $filter: ModelFullServiceSubjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceSubjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceSubjectsQueryVariables,
  APITypes.ListFullServiceSubjectsQuery
>;
export const syncFullServiceSubjects = /* GraphQL */ `query SyncFullServiceSubjects(
  $filter: ModelFullServiceSubjectFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceSubjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceSubjectsQueryVariables,
  APITypes.SyncFullServiceSubjectsQuery
>;
export const fullServiceSubjectsBySitesID = /* GraphQL */ `query FullServiceSubjectsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceSubjectFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceSubjectsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceSubjectsBySitesIDQueryVariables,
  APITypes.FullServiceSubjectsBySitesIDQuery
>;
export const searchFullServiceSubjects = /* GraphQL */ `query SearchFullServiceSubjects(
  $filter: SearchableFullServiceSubjectFilterInput
  $sort: [SearchableFullServiceSubjectSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFullServiceSubjectAggregationInput]
) {
  searchFullServiceSubjects(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFullServiceSubjectsQueryVariables,
  APITypes.SearchFullServiceSubjectsQuery
>;
export const getFullServiceExam = /* GraphQL */ `query GetFullServiceExam($id: ID!) {
  getFullServiceExam(id: $id) {
    id
    sitesID
    examName
    examDate
    examTime
    examDegree
    examFile
    FullServiceSubjectQuestions {
      nextToken
      startedAt
      __typename
    }
    FullServiceExplains {
      nextToken
      startedAt
      __typename
    }
    FullServiceUsers {
      nextToken
      startedAt
      __typename
    }
    FullServiceExamReviews {
      nextToken
      startedAt
      __typename
    }
    FullServiceSurveies {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceExamQueryVariables,
  APITypes.GetFullServiceExamQuery
>;
export const listFullServiceExams = /* GraphQL */ `query ListFullServiceExams(
  $filter: ModelFullServiceExamFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceExams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      examName
      examDate
      examTime
      examDegree
      examFile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceExamsQueryVariables,
  APITypes.ListFullServiceExamsQuery
>;
export const syncFullServiceExams = /* GraphQL */ `query SyncFullServiceExams(
  $filter: ModelFullServiceExamFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceExams(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      examName
      examDate
      examTime
      examDegree
      examFile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceExamsQueryVariables,
  APITypes.SyncFullServiceExamsQuery
>;
export const fullServiceExamsBySitesID = /* GraphQL */ `query FullServiceExamsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceExamFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceExamsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      examName
      examDate
      examTime
      examDegree
      examFile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceExamsBySitesIDQueryVariables,
  APITypes.FullServiceExamsBySitesIDQuery
>;
export const searchFullServiceExams = /* GraphQL */ `query SearchFullServiceExams(
  $filter: SearchableFullServiceExamFilterInput
  $sort: [SearchableFullServiceExamSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFullServiceExamAggregationInput]
) {
  searchFullServiceExams(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      examName
      examDate
      examTime
      examDegree
      examFile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFullServiceExamsQueryVariables,
  APITypes.SearchFullServiceExamsQuery
>;
export const getFullServiceSubjectQuestion = /* GraphQL */ `query GetFullServiceSubjectQuestion($id: ID!) {
  getFullServiceSubjectQuestion(id: $id) {
    id
    fullServiceExamID
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    FullServiceSubject {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceSubjectQuestionFullServiceSubjectId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceSubjectQuestionQueryVariables,
  APITypes.GetFullServiceSubjectQuestionQuery
>;
export const listFullServiceSubjectQuestions = /* GraphQL */ `query ListFullServiceSubjectQuestions(
  $filter: ModelFullServiceSubjectQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceSubjectQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceSubjectQuestionFullServiceSubjectId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceSubjectQuestionsQueryVariables,
  APITypes.ListFullServiceSubjectQuestionsQuery
>;
export const syncFullServiceSubjectQuestions = /* GraphQL */ `query SyncFullServiceSubjectQuestions(
  $filter: ModelFullServiceSubjectQuestionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceSubjectQuestions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fullServiceExamID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceSubjectQuestionFullServiceSubjectId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceSubjectQuestionsQueryVariables,
  APITypes.SyncFullServiceSubjectQuestionsQuery
>;
export const fullServiceSubjectQuestionsByFullServiceExamID = /* GraphQL */ `query FullServiceSubjectQuestionsByFullServiceExamID(
  $fullServiceExamID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceSubjectQuestionFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceSubjectQuestionsByFullServiceExamID(
    fullServiceExamID: $fullServiceExamID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceSubjectQuestionFullServiceSubjectId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceSubjectQuestionsByFullServiceExamIDQueryVariables,
  APITypes.FullServiceSubjectQuestionsByFullServiceExamIDQuery
>;
export const getFullServiceExplain = /* GraphQL */ `query GetFullServiceExplain($id: ID!) {
  getFullServiceExplain(id: $id) {
    id
    fullServiceExamID
    teacherName
    teacherImage
    subjectPC
    subjectMobile
    explainFile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceExplainQueryVariables,
  APITypes.GetFullServiceExplainQuery
>;
export const listFullServiceExplains = /* GraphQL */ `query ListFullServiceExplains(
  $filter: ModelFullServiceExplainFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceExplains(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      teacherName
      teacherImage
      subjectPC
      subjectMobile
      explainFile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceExplainsQueryVariables,
  APITypes.ListFullServiceExplainsQuery
>;
export const syncFullServiceExplains = /* GraphQL */ `query SyncFullServiceExplains(
  $filter: ModelFullServiceExplainFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceExplains(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fullServiceExamID
      teacherName
      teacherImage
      subjectPC
      subjectMobile
      explainFile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceExplainsQueryVariables,
  APITypes.SyncFullServiceExplainsQuery
>;
export const fullServiceExplainsByFullServiceExamID = /* GraphQL */ `query FullServiceExplainsByFullServiceExamID(
  $fullServiceExamID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceExplainFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceExplainsByFullServiceExamID(
    fullServiceExamID: $fullServiceExamID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      teacherName
      teacherImage
      subjectPC
      subjectMobile
      explainFile
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceExplainsByFullServiceExamIDQueryVariables,
  APITypes.FullServiceExplainsByFullServiceExamIDQuery
>;
export const getFullServiceUser = /* GraphQL */ `query GetFullServiceUser($id: ID!) {
  getFullServiceUser(id: $id) {
    id
    sitesID
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    gradingType
    FullServiceUserAnswers {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceUserQueryVariables,
  APITypes.GetFullServiceUserQuery
>;
export const listFullServiceUsers = /* GraphQL */ `query ListFullServiceUsers(
  $filter: ModelFullServiceUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      gradingType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceUsersQueryVariables,
  APITypes.ListFullServiceUsersQuery
>;
export const syncFullServiceUsers = /* GraphQL */ `query SyncFullServiceUsers(
  $filter: ModelFullServiceUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      gradingType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceUsersQueryVariables,
  APITypes.SyncFullServiceUsersQuery
>;
export const fullServiceUsersBySitesID = /* GraphQL */ `query FullServiceUsersBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceUserFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceUsersBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      gradingType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceUsersBySitesIDQueryVariables,
  APITypes.FullServiceUsersBySitesIDQuery
>;
export const fullServiceUsersByFullServiceExamID = /* GraphQL */ `query FullServiceUsersByFullServiceExamID(
  $fullServiceExamID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceUserFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceUsersByFullServiceExamID(
    fullServiceExamID: $fullServiceExamID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      gradingType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceUsersByFullServiceExamIDQueryVariables,
  APITypes.FullServiceUsersByFullServiceExamIDQuery
>;
export const searchFullServiceUsers = /* GraphQL */ `query SearchFullServiceUsers(
  $filter: SearchableFullServiceUserFilterInput
  $sort: [SearchableFullServiceUserSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFullServiceUserAggregationInput]
) {
  searchFullServiceUsers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      gradingType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFullServiceUsersQueryVariables,
  APITypes.SearchFullServiceUsersQuery
>;
export const getFullServiceUserAnswer = /* GraphQL */ `query GetFullServiceUserAnswer($id: ID!) {
  getFullServiceUserAnswer(id: $id) {
    id
    fullServiceUserID
    FullServiceSubjectQuestion {
      id
      fullServiceExamID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceSubjectQuestionFullServiceSubjectId
      __typename
    }
    answers {
      questionNumber
      answer
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceUserAnswerFullServiceSubjectQuestionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceUserAnswerQueryVariables,
  APITypes.GetFullServiceUserAnswerQuery
>;
export const listFullServiceUserAnswers = /* GraphQL */ `query ListFullServiceUserAnswers(
  $filter: ModelFullServiceUserAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceUserAnswers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceUserAnswerFullServiceSubjectQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceUserAnswersQueryVariables,
  APITypes.ListFullServiceUserAnswersQuery
>;
export const syncFullServiceUserAnswers = /* GraphQL */ `query SyncFullServiceUserAnswers(
  $filter: ModelFullServiceUserAnswerFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceUserAnswers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fullServiceUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceUserAnswerFullServiceSubjectQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceUserAnswersQueryVariables,
  APITypes.SyncFullServiceUserAnswersQuery
>;
export const fullServiceUserAnswersByFullServiceUserID = /* GraphQL */ `query FullServiceUserAnswersByFullServiceUserID(
  $fullServiceUserID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceUserAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceUserAnswersByFullServiceUserID(
    fullServiceUserID: $fullServiceUserID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceUserAnswerFullServiceSubjectQuestionId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceUserAnswersByFullServiceUserIDQueryVariables,
  APITypes.FullServiceUserAnswersByFullServiceUserIDQuery
>;
export const getFullServiceExamReview = /* GraphQL */ `query GetFullServiceExamReview($id: ID!) {
  getFullServiceExamReview(id: $id) {
    id
    fullServiceExamID
    title
    content
    isDisplay
    isBest
    likeCount
    FullServiceExamReviewLikes {
      nextToken
      startedAt
      __typename
    }
    sortValue
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceExamReviewQueryVariables,
  APITypes.GetFullServiceExamReviewQuery
>;
export const listFullServiceExamReviews = /* GraphQL */ `query ListFullServiceExamReviews(
  $filter: ModelFullServiceExamReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceExamReviews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      title
      content
      isDisplay
      isBest
      likeCount
      sortValue
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceExamReviewsQueryVariables,
  APITypes.ListFullServiceExamReviewsQuery
>;
export const syncFullServiceExamReviews = /* GraphQL */ `query SyncFullServiceExamReviews(
  $filter: ModelFullServiceExamReviewFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceExamReviews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fullServiceExamID
      title
      content
      isDisplay
      isBest
      likeCount
      sortValue
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceExamReviewsQueryVariables,
  APITypes.SyncFullServiceExamReviewsQuery
>;
export const fullServiceExamReviewsByFullServiceExamID = /* GraphQL */ `query FullServiceExamReviewsByFullServiceExamID(
  $fullServiceExamID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceExamReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceExamReviewsByFullServiceExamID(
    fullServiceExamID: $fullServiceExamID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      title
      content
      isDisplay
      isBest
      likeCount
      sortValue
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceExamReviewsByFullServiceExamIDQueryVariables,
  APITypes.FullServiceExamReviewsByFullServiceExamIDQuery
>;
export const searchFullServiceExamReviews = /* GraphQL */ `query SearchFullServiceExamReviews(
  $filter: SearchableFullServiceExamReviewFilterInput
  $sort: [SearchableFullServiceExamReviewSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFullServiceExamReviewAggregationInput]
) {
  searchFullServiceExamReviews(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      fullServiceExamID
      title
      content
      isDisplay
      isBest
      likeCount
      sortValue
      regMemberIdx
      regMemberId
      regMemberName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFullServiceExamReviewsQueryVariables,
  APITypes.SearchFullServiceExamReviewsQuery
>;
export const getFullServiceExamReviewLike = /* GraphQL */ `query GetFullServiceExamReviewLike($id: ID!) {
  getFullServiceExamReviewLike(id: $id) {
    id
    fullServiceExamReviewID
    regMemberIdx
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceExamReviewLikeQueryVariables,
  APITypes.GetFullServiceExamReviewLikeQuery
>;
export const listFullServiceExamReviewLikes = /* GraphQL */ `query ListFullServiceExamReviewLikes(
  $filter: ModelFullServiceExamReviewLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceExamReviewLikes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamReviewID
      regMemberIdx
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceExamReviewLikesQueryVariables,
  APITypes.ListFullServiceExamReviewLikesQuery
>;
export const syncFullServiceExamReviewLikes = /* GraphQL */ `query SyncFullServiceExamReviewLikes(
  $filter: ModelFullServiceExamReviewLikeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceExamReviewLikes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fullServiceExamReviewID
      regMemberIdx
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceExamReviewLikesQueryVariables,
  APITypes.SyncFullServiceExamReviewLikesQuery
>;
export const fullServiceExamReviewLikesByFullServiceExamReviewID = /* GraphQL */ `query FullServiceExamReviewLikesByFullServiceExamReviewID(
  $fullServiceExamReviewID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceExamReviewLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceExamReviewLikesByFullServiceExamReviewID(
    fullServiceExamReviewID: $fullServiceExamReviewID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamReviewID
      regMemberIdx
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceExamReviewLikesByFullServiceExamReviewIDQueryVariables,
  APITypes.FullServiceExamReviewLikesByFullServiceExamReviewIDQuery
>;
export const searchFullServiceExamReviewLikes = /* GraphQL */ `query SearchFullServiceExamReviewLikes(
  $filter: SearchableFullServiceExamReviewLikeFilterInput
  $sort: [SearchableFullServiceExamReviewLikeSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFullServiceExamReviewLikeAggregationInput]
) {
  searchFullServiceExamReviewLikes(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      fullServiceExamReviewID
      regMemberIdx
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFullServiceExamReviewLikesQueryVariables,
  APITypes.SearchFullServiceExamReviewLikesQuery
>;
export const getFullServiceDisplayBoard = /* GraphQL */ `query GetFullServiceDisplayBoard($id: ID!) {
  getFullServiceDisplayBoard(id: $id) {
    id
    sitesID
    displayBoardCode
    content
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceDisplayBoardQueryVariables,
  APITypes.GetFullServiceDisplayBoardQuery
>;
export const listFullServiceDisplayBoards = /* GraphQL */ `query ListFullServiceDisplayBoards(
  $filter: ModelFullServiceDisplayBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceDisplayBoards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      displayBoardCode
      content
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceDisplayBoardsQueryVariables,
  APITypes.ListFullServiceDisplayBoardsQuery
>;
export const syncFullServiceDisplayBoards = /* GraphQL */ `query SyncFullServiceDisplayBoards(
  $filter: ModelFullServiceDisplayBoardFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceDisplayBoards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      displayBoardCode
      content
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceDisplayBoardsQueryVariables,
  APITypes.SyncFullServiceDisplayBoardsQuery
>;
export const fullServiceDisplayBoardsBySitesID = /* GraphQL */ `query FullServiceDisplayBoardsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceDisplayBoardFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceDisplayBoardsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      displayBoardCode
      content
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceDisplayBoardsBySitesIDQueryVariables,
  APITypes.FullServiceDisplayBoardsBySitesIDQuery
>;
export const searchFullServiceDisplayBoards = /* GraphQL */ `query SearchFullServiceDisplayBoards(
  $filter: SearchableFullServiceDisplayBoardFilterInput
  $sort: [SearchableFullServiceDisplayBoardSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFullServiceDisplayBoardAggregationInput]
) {
  searchFullServiceDisplayBoards(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      displayBoardCode
      content
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFullServiceDisplayBoardsQueryVariables,
  APITypes.SearchFullServiceDisplayBoardsQuery
>;
export const getFullServiceSurvey = /* GraphQL */ `query GetFullServiceSurvey($id: ID!) {
  getFullServiceSurvey(id: $id) {
    id
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    survey
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFullServiceSurveyQueryVariables,
  APITypes.GetFullServiceSurveyQuery
>;
export const listFullServiceSurveys = /* GraphQL */ `query ListFullServiceSurveys(
  $filter: ModelFullServiceSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  listFullServiceSurveys(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      survey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFullServiceSurveysQueryVariables,
  APITypes.ListFullServiceSurveysQuery
>;
export const syncFullServiceSurveys = /* GraphQL */ `query SyncFullServiceSurveys(
  $filter: ModelFullServiceSurveyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFullServiceSurveys(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      survey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFullServiceSurveysQueryVariables,
  APITypes.SyncFullServiceSurveysQuery
>;
export const fullServiceSurveysByFullServiceExamID = /* GraphQL */ `query FullServiceSurveysByFullServiceExamID(
  $fullServiceExamID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFullServiceSurveyFilterInput
  $limit: Int
  $nextToken: String
) {
  fullServiceSurveysByFullServiceExamID(
    fullServiceExamID: $fullServiceExamID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      survey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullServiceSurveysByFullServiceExamIDQueryVariables,
  APITypes.FullServiceSurveysByFullServiceExamIDQuery
>;
export const searchFullServiceSurveys = /* GraphQL */ `query SearchFullServiceSurveys(
  $filter: SearchableFullServiceSurveyFilterInput
  $sort: [SearchableFullServiceSurveySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableFullServiceSurveyAggregationInput]
) {
  searchFullServiceSurveys(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      fullServiceExamID
      userIdx
      userId
      userName
      testNumber
      survey
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchFullServiceSurveysQueryVariables,
  APITypes.SearchFullServiceSurveysQuery
>;
export const getTextContents = /* GraphQL */ `query GetTextContents($id: ID!) {
  getTextContents(id: $id) {
    id
    sitesID
    textContentsCode
    title
    description
    subDescription
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTextContentsQueryVariables,
  APITypes.GetTextContentsQuery
>;
export const listTextContents = /* GraphQL */ `query ListTextContents(
  $filter: ModelTextContentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listTextContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      textContentsCode
      title
      description
      subDescription
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTextContentsQueryVariables,
  APITypes.ListTextContentsQuery
>;
export const syncTextContents = /* GraphQL */ `query SyncTextContents(
  $filter: ModelTextContentsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTextContents(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      textContentsCode
      title
      description
      subDescription
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTextContentsQueryVariables,
  APITypes.SyncTextContentsQuery
>;
export const textContentsBySitesID = /* GraphQL */ `query TextContentsBySitesID(
  $sitesID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTextContentsFilterInput
  $limit: Int
  $nextToken: String
) {
  textContentsBySitesID(
    sitesID: $sitesID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sitesID
      textContentsCode
      title
      description
      subDescription
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TextContentsBySitesIDQueryVariables,
  APITypes.TextContentsBySitesIDQuery
>;
export const searchTextContents = /* GraphQL */ `query SearchTextContents(
  $filter: SearchableTextContentsFilterInput
  $sort: [SearchableTextContentsSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTextContentsAggregationInput]
) {
  searchTextContents(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      textContentsCode
      title
      description
      subDescription
      linkURL
      isUse
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTextContentsQueryVariables,
  APITypes.SearchTextContentsQuery
>;
export const getAdminLog = /* GraphQL */ `query GetAdminLog($id: ID!) {
  getAdminLog(id: $id) {
    id
    siteCode
    key
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminLogQueryVariables,
  APITypes.GetAdminLogQuery
>;
export const listAdminLogs = /* GraphQL */ `query ListAdminLogs(
  $filter: ModelAdminLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      siteCode
      key
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminLogsQueryVariables,
  APITypes.ListAdminLogsQuery
>;
export const syncAdminLogs = /* GraphQL */ `query SyncAdminLogs(
  $filter: ModelAdminLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      siteCode
      key
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminLogsQueryVariables,
  APITypes.SyncAdminLogsQuery
>;
export const searchAdminLogs = /* GraphQL */ `query SearchAdminLogs(
  $filter: SearchableAdminLogFilterInput
  $sort: [SearchableAdminLogSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableAdminLogAggregationInput]
) {
  searchAdminLogs(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      siteCode
      key
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchAdminLogsQueryVariables,
  APITypes.SearchAdminLogsQuery
>;
export const getLiveSchedule = /* GraphQL */ `query GetLiveSchedule($id: ID!) {
  getLiveSchedule(id: $id) {
    id
    sitesID
    liveDate
    liveBeginTime
    liveEndTime
    liveTypeCode
    title
    description
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    liveURL
    isUseReplay
    isPause
    pauseId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLiveScheduleQueryVariables,
  APITypes.GetLiveScheduleQuery
>;
export const listLiveSchedules = /* GraphQL */ `query ListLiveSchedules(
  $filter: ModelLiveScheduleFilterInput
  $limit: Int
  $nextToken: String
) {
  listLiveSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      liveDate
      liveBeginTime
      liveEndTime
      liveTypeCode
      title
      description
      liveURL
      isUseReplay
      isPause
      pauseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLiveSchedulesQueryVariables,
  APITypes.ListLiveSchedulesQuery
>;
export const syncLiveSchedules = /* GraphQL */ `query SyncLiveSchedules(
  $filter: ModelLiveScheduleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLiveSchedules(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      liveDate
      liveBeginTime
      liveEndTime
      liveTypeCode
      title
      description
      liveURL
      isUseReplay
      isPause
      pauseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLiveSchedulesQueryVariables,
  APITypes.SyncLiveSchedulesQuery
>;
export const searchLiveSchedules = /* GraphQL */ `query SearchLiveSchedules(
  $filter: SearchableLiveScheduleFilterInput
  $sort: [SearchableLiveScheduleSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableLiveScheduleAggregationInput]
) {
  searchLiveSchedules(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      liveDate
      liveBeginTime
      liveEndTime
      liveTypeCode
      title
      description
      liveURL
      isUseReplay
      isPause
      pauseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchLiveSchedulesQueryVariables,
  APITypes.SearchLiveSchedulesQuery
>;
export const getLiveReplay = /* GraphQL */ `query GetLiveReplay($id: ID!) {
  getLiveReplay(id: $id) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    lectureCategoryCode
    replayTypeCode
    liveScheduleId
    lectureName
    replayURL
    thumbnail
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    isUseReady
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReplayLiveReplayId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLiveReplayQueryVariables,
  APITypes.GetLiveReplayQuery
>;
export const listLiveReplays = /* GraphQL */ `query ListLiveReplays(
  $filter: ModelLiveReplayFilterInput
  $limit: Int
  $nextToken: String
) {
  listLiveReplays(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLiveReplaysQueryVariables,
  APITypes.ListLiveReplaysQuery
>;
export const syncLiveReplays = /* GraphQL */ `query SyncLiveReplays(
  $filter: ModelLiveReplayFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLiveReplays(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLiveReplaysQueryVariables,
  APITypes.SyncLiveReplaysQuery
>;
export const searchLiveReplays = /* GraphQL */ `query SearchLiveReplays(
  $filter: SearchableLiveReplayFilterInput
  $sort: [SearchableLiveReplaySortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableLiveReplayAggregationInput]
) {
  searchLiveReplays(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchLiveReplaysQueryVariables,
  APITypes.SearchLiveReplaysQuery
>;
export const getLiveReservation = /* GraphQL */ `query GetLiveReservation($id: ID!) {
  getLiveReservation(id: $id) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    LiveSchedule {
      id
      sitesID
      liveDate
      liveBeginTime
      liveEndTime
      liveTypeCode
      title
      description
      liveURL
      isUseReplay
      isPause
      pauseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    userIdx
    userId
    userName
    userTel
    userEmail
    userInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReservationLiveReplayId
    liveReservationLiveScheduleId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLiveReservationQueryVariables,
  APITypes.GetLiveReservationQuery
>;
export const listLiveReservations = /* GraphQL */ `query ListLiveReservations(
  $filter: ModelLiveReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLiveReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      userIdx
      userId
      userName
      userTel
      userEmail
      userInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReservationLiveReplayId
      liveReservationLiveScheduleId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLiveReservationsQueryVariables,
  APITypes.ListLiveReservationsQuery
>;
export const syncLiveReservations = /* GraphQL */ `query SyncLiveReservations(
  $filter: ModelLiveReservationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLiveReservations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      userIdx
      userId
      userName
      userTel
      userEmail
      userInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReservationLiveReplayId
      liveReservationLiveScheduleId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLiveReservationsQueryVariables,
  APITypes.SyncLiveReservationsQuery
>;
export const searchLiveReservations = /* GraphQL */ `query SearchLiveReservations(
  $filter: SearchableLiveReservationFilterInput
  $sort: [SearchableLiveReservationSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableLiveReservationAggregationInput]
) {
  searchLiveReservations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      userIdx
      userId
      userName
      userTel
      userEmail
      userInfo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReservationLiveReplayId
      liveReservationLiveScheduleId
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchLiveReservationsQueryVariables,
  APITypes.SearchLiveReservationsQuery
>;
export const getLiveApplication = /* GraphQL */ `query GetLiveApplication($id: ID!) {
  getLiveApplication(id: $id) {
    id
    sitesID
    userIdx
    userId
    userName
    content
    isDisplay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLiveApplicationQueryVariables,
  APITypes.GetLiveApplicationQuery
>;
export const listLiveApplications = /* GraphQL */ `query ListLiveApplications(
  $filter: ModelLiveApplicationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLiveApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sitesID
      userIdx
      userId
      userName
      content
      isDisplay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLiveApplicationsQueryVariables,
  APITypes.ListLiveApplicationsQuery
>;
export const syncLiveApplications = /* GraphQL */ `query SyncLiveApplications(
  $filter: ModelLiveApplicationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLiveApplications(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sitesID
      userIdx
      userId
      userName
      content
      isDisplay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLiveApplicationsQueryVariables,
  APITypes.SyncLiveApplicationsQuery
>;
export const searchLiveApplications = /* GraphQL */ `query SearchLiveApplications(
  $filter: SearchableLiveApplicationFilterInput
  $sort: [SearchableLiveApplicationSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableLiveApplicationAggregationInput]
) {
  searchLiveApplications(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      sitesID
      userIdx
      userId
      userName
      content
      isDisplay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchLiveApplicationsQueryVariables,
  APITypes.SearchLiveApplicationsQuery
>;
export const getEventCardinalBenefit = /* GraphQL */ `query GetEventCardinalBenefit($id: ID!) {
  getEventCardinalBenefit(id: $id) {
    id
    eventId
    eventCode
    siteCode
    benefitMemberCount
    benefitCycle
    Lastbenefit
    benefitItems {
      benefitType
      benefitCode
      benefitName
      __typename
    }
    benefitCardinalResults {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      benefitMembers
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventCardinalBenefitQueryVariables,
  APITypes.GetEventCardinalBenefitQuery
>;
export const listEventCardinalBenefits = /* GraphQL */ `query ListEventCardinalBenefits(
  $filter: ModelEventCardinalBenefitFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventCardinalBenefits(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      eventCode
      siteCode
      benefitMemberCount
      benefitCycle
      Lastbenefit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventCardinalBenefitsQueryVariables,
  APITypes.ListEventCardinalBenefitsQuery
>;
export const syncEventCardinalBenefits = /* GraphQL */ `query SyncEventCardinalBenefits(
  $filter: ModelEventCardinalBenefitFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEventCardinalBenefits(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      eventId
      eventCode
      siteCode
      benefitMemberCount
      benefitCycle
      Lastbenefit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEventCardinalBenefitsQueryVariables,
  APITypes.SyncEventCardinalBenefitsQuery
>;
export const searchEventCardinalBenefits = /* GraphQL */ `query SearchEventCardinalBenefits(
  $filter: SearchableEventCardinalBenefitFilterInput
  $sort: [SearchableEventCardinalBenefitSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableEventCardinalBenefitAggregationInput]
) {
  searchEventCardinalBenefits(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      eventId
      eventCode
      siteCode
      benefitMemberCount
      benefitCycle
      Lastbenefit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchEventCardinalBenefitsQueryVariables,
  APITypes.SearchEventCardinalBenefitsQuery
>;
